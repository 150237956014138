import {ButtonIcon, Modal} from '@prescriberpoint/ui';
import {Ellipsis} from 'lucide-react';
import {FC, useState} from 'react';
import BaseStep from './BaseStep';
import FavoriteStep from './FavoriteStep';
import RemsStep from './RemsStep';
import ShareStep from './ShareStep';

export type Step = {
  name: string;
  value: number;
};

export const REMS_STEP_NUMBER = 1;
export const FAVORITE_STEP_NUMBER = 2;
export const SHARE_STEP_NUMBER = 3;

export const STEPS: Step[] = [
  {name: 'base', value: 0},
  {name: 'Rems Info', value: REMS_STEP_NUMBER},
  {name: 'Favorite', value: FAVORITE_STEP_NUMBER},
  {name: 'Share', value: SHARE_STEP_NUMBER},
];

interface IMenuExtraDataProps {
  showRems: boolean;
}

const MenuExtraData: FC<IMenuExtraDataProps> = ({showRems}) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [step, setStep] = useState(0);

  const handleOnClick = (stepNumber: number) => {
    setStep(stepNumber);
  };

  return (
    <>
      <ButtonIcon
        id='open_hero_icons'
        size='sm'
        className='shrink-0'
        Icon={Ellipsis}
        variant='default'
        onClick={() => setModalOpen(true)}
      />
      <Modal
        isOpen={modalOpen}
        closeModal={() => setModalOpen(false)}
        showCloseButton
        position='side'
        onGoBack={() => setStep(0)}
        showGoBack={step !== 0}
        showConfirm={false}
        showCancel={false}>
        {step === 0 ? (
          <BaseStep showRems={showRems} steps={STEPS} onClick={handleOnClick} />
        ) : null}
        {step === REMS_STEP_NUMBER && showRems ? <RemsStep /> : null}
        {step === FAVORITE_STEP_NUMBER ? <FavoriteStep /> : null}
        {step === SHARE_STEP_NUMBER ? <ShareStep /> : null}
      </Modal>
    </>
  );
};

export default MenuExtraData;
