import dynamic from 'next/dynamic';
import {useRouter} from 'next/router';
import {FC, useCallback} from 'react';
import {useCookies} from 'react-cookie';
import {COOKIE_CONSENT_COOKIE} from '@/constants/cookies';
import {IS_PRODUCTION} from '@/constants/global';
import {useAuthPath, useHandleSetCookies, useUser} from '@/hooks';
import {useRemoveAllCookies} from '@/hooks/useRemoveAllCookies';

const CookiePolicyModal = dynamic(() => import('./CookiePolicyModal'));

export const COOKIE_CONSENT_ERROR =
  'Cookies are disabled. Please enable cookies and try again';

interface CookieConsentProps {
  isModalActive: boolean;
  toggleModal: () => void;
  onSave?: () => void;
}

const CookieConsent: FC<CookieConsentProps> = ({
  isModalActive,
  toggleModal,
  onSave,
}) => {
  const [cookies] = useCookies();
  const currentConsent = JSON.parse(cookies[COOKIE_CONSENT_COOKIE] || 'true');
  const {logged} = useUser();
  const {logoutPath} = useAuthPath();
  const {handleSetCookie} = useHandleSetCookies();
  const router = useRouter();
  const {removeAllCookies} = useRemoveAllCookies();

  const handleCookieConsent = useCallback(
    (consent: boolean) => {
      if (consent) {
        handleSetCookie(COOKIE_CONSENT_COOKIE, consent, {
          maxAge: 24 * 60 * 60,
          path: '/',
        });
      } else {
        removeAllCookies();
        if (IS_PRODUCTION) {
          //@ts-ignore
          window.clarity?.('consent', false);
        }
        handleSetCookie(COOKIE_CONSENT_COOKIE, consent, {
          maxAge: 24 * 60 * 60,
          path: '/',
        });
        if (logged) {
          router.push(logoutPath);
        }
      }
    },
    [handleSetCookie, removeAllCookies, logged, router, logoutPath],
  );

  const handleModalSave = useCallback(
    (consent: boolean) => {
      toggleModal();
      handleCookieConsent(consent);
      onSave && onSave();
      window.location.reload();
    },
    [toggleModal, handleCookieConsent, onSave],
  );

  return isModalActive ? (
    <CookiePolicyModal
      isOpen={isModalActive}
      handleCookieConsent={handleModalSave}
      currentConsent={currentConsent}
      closeModal={toggleModal}
    />
  ) : null;
};

export default CookieConsent;
