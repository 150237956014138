export const SPLIT_USER_COOKIE = 'split_user_key_fingerprint';
export const COOKIE_CONSENT_COOKIE = 'cookie_consent';
export const NPI_COOKIE = 'npi';
export const IS_HCP_COOKIE = 'is_hcp';
export const INTERNAL_TRAFFIC_COOKIE = 'InternalTraffic';
export const ACCESS_TOKEN_COOKIE = 'accessToken';
export const PA_DOWNLOAD_COUNTER_COOKIE = 'paDownloadCounter';
export const SURVEY_COMPLETE_COOKIE = 'survey_complete';
export const HUBSPOT_UTK_COOKIE = 'hubspotutk';
export const AZURE_FRONT_DOOR_COOKIES = ['ASLBSACORS', 'ASLBSA'];

// PPSamples cookies
const PPSAMPLES_COOKIES = ['cart'];

export const NECESSARY_COOKIES = [
  ACCESS_TOKEN_COOKIE,
  NPI_COOKIE,
  INTERNAL_TRAFFIC_COOKIE,
  IS_HCP_COOKIE,
  SURVEY_COMPLETE_COOKIE,
  PA_DOWNLOAD_COUNTER_COOKIE,
  COOKIE_CONSENT_COOKIE,
  ...AZURE_FRONT_DOOR_COOKIES,
  ...PPSAMPLES_COOKIES,
];
