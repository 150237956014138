export interface AffordabilityDetailsDto {
  id: string;
  programName: string;
  eligibilities: string[];
  fundLevels: string[];
  programUrl: string;
  helpLine: string;
  assistanceType: string;
  programRequirements: string[];
  eligibilityRequirements: string[];
  hasEnrollmentRequirements: boolean;
  enrollmentUrl: string;
  expirationDate: string;
  hasIncomeRequirements: boolean;
  incomeRequirements: string[];
  estimatedApplicationTime: string;
  annualCapCost: string | null;
  maxBenefit?: string | null;
  isRenewable: boolean;
  lastUpdatedDate: string;
  patientFixedCost?: number | null;
  maxDollarBenefitsPerYear?: number | null;
  maxTimesPerYear?: number | null;
  documentUrls: string[];
  pageUrls: string[];
}

export interface AffordabilityDetailsResponse {
  highlightedProgram: AffordabilityDetailsDto | null;
  availablePrograms: AffordabilityDetailsDto[];
  hasPrograms: boolean;
  totalPrograms: number;
}

export const AFFORDABILITY_NOT_AVAILABLE_STRING_VALUE = 'Data Not Available';
