import {customEvent, Button, toSnakeCase, Text} from '@prescriberpoint/ui';
import clsx from 'clsx';
import {format, subDays} from 'date-fns';
import {useRouter} from 'next/router';
import {FC, useCallback, useEffect, useMemo, useRef, useState} from 'react';
import {useCookies} from 'react-cookie';
import {Link} from 'react-scroll';
import OverviewLink from './OverviewLink';
import {CEVENT_NAVIGATE, DlvNavigate} from '@/constants';
import {SPLIT_USER_COOKIE} from '@/constants/cookies';
import * as FLAGS from '@/constants/flags';
import {
  SIDEBAR_PIVOT_OFFSET_SCROLL,
  OFFSET_SCROLL_WITH_PDP_HEADER_V2,
} from '@/constants/pdpPage';
import {
  ENHANCED_SECTION_IDS,
  SECTION_IDS as FULL_IDS,
  FULL_SECTION_IDS,
  OTHER_SECTION_IDS,
  SUBPAGES_SECTION_IDS,
} from '@/constants/sectionIDs';
import {
  useFlag,
  useMobileContentContext,
  usePharmacyContext,
  useUserAgentContext,
} from '@/context';
import {useLoadingContext} from '@/context/LoadingContext';
import {
  useCurrentSlug,
  useIsCoverageToolAvailable,
  useRenderInIFrame,
  useSectionIds,
  useSectionsStatus,
  useShowContentSummaries,
  useShowEnhancedSection,
  useShowFixedCTAMenu,
  useUpdateEffect,
} from '@/hooks';
import useIsPDPSubpage from '@/hooks/useIsPDPSubpage';
import {getSectionTitleFromId} from '@/modules/ProductDetailPage/utils';
import {useAffordabilityDetails, useEnhancedContent, useLabel} from '@/queries';
import {isElementVisible} from '@/utils/ui';

export const PIVOT_PREFIX_ID = 'ss_pivot_';

export const VIEW_PORT_OFFSET = 0;

interface ISidebarPivotMenuProps {
  isOpen: boolean;
}

const SidebarPivotMenu: FC<ISidebarPivotMenuProps> = ({isOpen}) => {
  const {setId, slug, section} = useCurrentSlug();
  const {setShowCTAMenu} = useMobileContentContext();
  const isPDPSubpage = useIsPDPSubpage();
  const {data: product} = useLabel(setId);
  const {isFetching: isInitialLoading} = useEnhancedContent(setId, {
    enabled: !!setId,
  });
  const showNewAffordability = useFlag(FLAGS.AFFORDABILITY_PDP_SECTION);
  const {isPending: isPendingAffordability} = useAffordabilityDetails(setId, {
    enabled: !!setId && showNewAffordability,
  });

  const showCTAFixedMenu = useShowFixedCTAMenu();

  const {anchors, sectionIds: SECTION_IDS} = useSectionIds();
  const pdpIsEmbedded = useRenderInIFrame();
  const router = useRouter();
  const [activeItem, setActiveItem] = useState('');
  const pivotItemsPositions = useRef(null);
  const {isLoading} = useLoadingContext();
  const muteScrolling = useRef(false);
  const {isMobileOrTablet} = useUserAgentContext();
  const {showPH} = usePharmacyContext();
  const showPA = useShowEnhancedSection(
    FULL_IDS.priorAuthorization,
    FLAGS.PRIOR_AUTHORIZATION_SECTION,
  );
  const showRS = useShowEnhancedSection(
    FULL_IDS.requestSamples,
    FLAGS.REQUEST_SAMPLES_SECTION,
    true,
  );

  const showFAQS =
    useShowEnhancedSection(
      FULL_IDS.peopleAlsoAsk,
      FLAGS.PEOPLE_ALSO_ASK_SECTION,
      false,
    ) && !pdpIsEmbedded;

  const showPubMed = useFlag(FLAGS.PUB_MED_ARTICLES);

  const {showCoverageOnPdp} = useIsCoverageToolAvailable();
  const showNewDesign = useFlag(FLAGS.SHOW_V2_PPT_DESIGN);
  const showContentSummaries = useShowContentSummaries(setId);

  const [cookies] = useCookies(['hubspotutk', SPLIT_USER_COOKIE]);
  const refFireCEvent = useRef(false);

  const getSectionTitle = useCallback(
    (id: string) => {
      if (
        id === SECTION_IDS.summary ||
        id === OTHER_SECTION_IDS.dosingAndAdmiministration
      ) {
        return showContentSummaries
          ? 'Quick Reference'
          : 'Dosage & Administration';
      }

      if (id === SECTION_IDS.pubMed) {
        return 'PubMed™ News';
      }

      if (id === SECTION_IDS.coverage) {
        return 'Coverage';
      }

      if (id === SECTION_IDS.peopleAlsoAsk) {
        return 'FAQ';
      }

      return getSectionTitleFromId(id);
    },
    [SECTION_IDS, showContentSummaries],
  );

  useEffect(() => {
    // Get the anchor value from the URL
    const anchor = router?.asPath?.split('#')[1];

    if (
      anchor &&
      product &&
      !isInitialLoading &&
      (anchor !== ENHANCED_SECTION_IDS.financialAssistance ||
        (showNewAffordability && !isPendingAffordability))
    ) {
      muteScrolling.current = true;
      setTimeout(() => {
        document.getElementById(`ss_pivot_${anchor}`)?.click();
        setTimeout(() => {
          muteScrolling.current = false;
        }, 1001); //As the animation takes 1000ms
      }, 1000);
    }
  }, [
    isInitialLoading,
    isPendingAffordability,
    product,
    router?.asPath,
    showNewAffordability,
  ]);

  useEffect(() => {
    if (isPDPSubpage) {
      const currentSection = SUBPAGES_SECTION_IDS[section];
      setActiveItem(currentSection);
    }
  }, [isPDPSubpage, section]);

  const sectionsStatus = useSectionsStatus();
  const showFA = sectionsStatus[FULL_SECTION_IDS.financialAssistance];

  const filteredAnchors = useMemo(
    () =>
      anchors.filter((item) => {
        const dosage =
          getSectionTitle(item) === 'Dosage & Administration' && pdpIsEmbedded;
        const label = getSectionTitle(item) === 'Drug label' && pdpIsEmbedded;
        const samples =
          getSectionTitle(item) === 'Samples' && (pdpIsEmbedded || !showRS);
        const pharmacy =
          getSectionTitle(item) === 'Pharmacy' && (pdpIsEmbedded || !showPH);
        const priorAuthorization =
          getSectionTitle(item) === 'Prior authorization' && !showPA;
        const financialAssistance =
          getSectionTitle(item) === 'Financial assistance' && !showFA;
        const faqs = getSectionTitle(item) === 'FAQ' && !showFAQS;
        const pubMed = getSectionTitle(item) === 'PubMed™ News' && !showPubMed;
        getSectionTitle(item);
        const coverage =
          getSectionTitle(item) === 'Coverage' && !showCoverageOnPdp;

        if (
          dosage ||
          label ||
          pharmacy ||
          samples ||
          priorAuthorization ||
          financialAssistance ||
          faqs ||
          pubMed ||
          coverage
        ) {
          return false;
        }
        return item;
      }),
    [
      anchors,
      getSectionTitle,
      pdpIsEmbedded,
      showRS,
      showPH,
      showPA,
      showFA,
      showFAQS,
      showPubMed,
      showCoverageOnPdp,
    ],
  );

  useEffect(() => {
    const pdpWrapper = document.scrollingElement!;
    if (isLoading) {
      pdpWrapper.scrollTo({
        top: 0,
      });
      setActiveItem(filteredAnchors[-1]);
    }
  }, [isLoading, filteredAnchors]);

  useUpdateEffect(() => {
    if (activeItem && !isMobileOrTablet && !isPDPSubpage) {
      window.history.replaceState(window.history.state, '', `#${activeItem}`);
    }
  }, [activeItem]);

  const checkIfPdpHeaderV1IsVisible = useCallback(() => {
    if (showNewDesign) {
      const pdpHeaderV1 = document.querySelector('#pdp-header-v1');
      const isPdpHeaderV1Visible = pdpHeaderV1
        ? isElementVisible(
            pdpHeaderV1.getBoundingClientRect(),
            VIEW_PORT_OFFSET,
          )
        : false;

      setShowCTAMenu(isPdpHeaderV1Visible);
    }
  }, [setShowCTAMenu, showNewDesign]);

  const hasScrolledToTop = useCallback(() => {
    const firstAnchor = filteredAnchors[0];
    const el = document.querySelector(`#${firstAnchor}`);

    if (!el) {
      return false;
    }

    return (
      el.getBoundingClientRect().top >
      (showCTAFixedMenu
        ? OFFSET_SCROLL_WITH_PDP_HEADER_V2
        : SIDEBAR_PIVOT_OFFSET_SCROLL)
    );
  }, [filteredAnchors, showCTAFixedMenu]);

  const hasScrolledToBottom = () => {
    const pdpWrapper = document.scrollingElement!;

    const hasScrolledToBottomVal =
      Math.floor(pdpWrapper.scrollHeight - pdpWrapper.scrollTop) ===
      pdpWrapper.clientHeight;

    return hasScrolledToBottomVal;
  };

  const isIntercomOpened = () => {
    const hasIntercomOpened = document
      .getElementsByTagName('html')[0]
      .classList.contains('intercom-mobile-messenger-active');

    return hasIntercomOpened;
  };

  const scrollHandler = useCallback(() => {
    if (showCTAFixedMenu) {
      checkIfPdpHeaderV1IsVisible();
    }

    if (isIntercomOpened()) {
      setActiveItem('');
      const pivotEl = document.querySelector('#pivotMenu')!;

      if (pivotEl) {
        pivotEl.scrollTo({
          left: 0,
          behavior: 'smooth',
        });
      }
      return;
    }

    if (muteScrolling.current || isPDPSubpage) {
      return;
    }

    if (hasScrolledToBottom()) {
      setActiveItem(filteredAnchors[filteredAnchors.length - 1]);
      return;
    }

    if (hasScrolledToTop()) {
      setActiveItem('');
      return;
    }

    const visibleItem = filteredAnchors.find((anchor) => {
      const el = document.querySelector(`#${anchor}`);

      if (!el) {
        return false;
      }

      return isElementVisible(
        el.getBoundingClientRect(),
        VIEW_PORT_OFFSET,
        showCTAFixedMenu ? 30 : undefined,
      );
    });

    if (visibleItem) {
      setActiveItem(visibleItem);
    }
  }, [
    filteredAnchors,
    isPDPSubpage,
    checkIfPdpHeaderV1IsVisible,
    hasScrolledToTop,
    showCTAFixedMenu,
  ]);

  const handleItemClick = useCallback(
    (anchor: string, isTrusted: boolean) => {
      if (showCTAFixedMenu) {
        checkIfPdpHeaderV1IsVisible();
      }

      if (isMobileOrTablet) {
        window.history.replaceState(window.history.state, '', `#${anchor}`);
      }
      muteScrolling.current = true;
      setActiveItem(anchor);
      if (refFireCEvent.current && isTrusted) {
        customEvent<DlvNavigate>(CEVENT_NAVIGATE, {
          pageSection: getSectionTitleFromId(anchor),
          userKey: cookies[SPLIT_USER_COOKIE],
        });
      }
      setTimeout(() => {
        refFireCEvent.current = true;
        muteScrolling.current = false;
      }, 1001); //As the animation takes 1000ms

      if (isPDPSubpage && anchor !== SUBPAGES_SECTION_IDS[section]) {
        router.push(`/therapies/${slug}#${anchor}`);
      }
    },
    [
      showCTAFixedMenu,
      isMobileOrTablet,
      isPDPSubpage,
      router,
      section,
      slug,
      cookies,
      checkIfPdpHeaderV1IsVisible,
    ],
  );

  //get pivot items positions for pivot horizontal scrolling
  useEffect(() => {
    if (!pivotItemsPositions.current) {
      let initialPositions: any = {};
      filteredAnchors.forEach((section) => {
        const pivotItemElement = document.querySelector(
          `#${PIVOT_PREFIX_ID}${section}`,
        );
        if (pivotItemElement) {
          initialPositions[section] =
            pivotItemElement?.getBoundingClientRect().left;
        }
      });
      pivotItemsPositions.current = initialPositions;
    }
  }, [filteredAnchors]);

  const debouncedScrollAnimationRef = useRef<number | null>(null);

  useEffect(() => {
    const debouncedScrollHandler = () => {
      if (debouncedScrollAnimationRef.current) {
        cancelAnimationFrame(debouncedScrollAnimationRef.current);
      }

      debouncedScrollAnimationRef.current = requestAnimationFrame(() => {
        scrollHandler();
      });
    };
    window.addEventListener('scroll', debouncedScrollHandler, true);
    return () => window.removeEventListener('scroll', debouncedScrollHandler);
  }, [scrollHandler]);

  //horizontal scroll into view selected pivot item
  useEffect(() => {
    const anchorElem = document?.querySelector(
      `#${PIVOT_PREFIX_ID}${activeItem}`,
    );

    if (anchorElem && pivotItemsPositions?.current) {
      const pivotEl = document.querySelector('#pivotMenu')!;
      const initialLeftPosition =
        pivotItemsPositions?.current[filteredAnchors[0]]!;
      pivotEl.scrollTo({
        left: pivotItemsPositions?.current[activeItem] - initialLeftPosition,
        behavior: 'smooth',
      });
    }
  }, [activeItem, filteredAnchors]);

  const yesterdaysDate = format(subDays(new Date(), 1), 'MMM, dd yyyy');

  return (
    <>
      <div
        className={clsx('lg:hidden', {
          'background fixed z-30 h-full w-screen bg-black opacity-50': isOpen,
        })}
      />
      <div
        className={clsx(
          'fixed left-[-251px] z-30 h-full w-[251px] transform bg-white transition-transform lg:static lg:w-[248px] lg:transform-none',
          {
            'translate-x-0': !isOpen,
            'translate-x-full lg:translate-x-0': isOpen,
          },
        )}>
        <div
          className={clsx('flex flex-col space-y-6 p-6 lg:fixed')}
          id='pivotMenu'
          data-testid='pivotMenu'>
          <Text as='overline-xs' size='xs' className='text-blue-500'>
            {`Last Update: ${yesterdaysDate}`}
          </Text>
          <div className='flex flex-col space-y-3'>
            <OverviewLink activeItem={!activeItem} />
            {filteredAnchors.map((item) => (
              <Link
                key={item}
                to={item}
                smooth='easeInOutQuart'
                duration={1000}
                role='tab'
                offset={
                  showCTAFixedMenu
                    ? -OFFSET_SCROLL_WITH_PDP_HEADER_V2
                    : -SIDEBAR_PIVOT_OFFSET_SCROLL
                }
                id={`${PIVOT_PREFIX_ID}${toSnakeCase(item)}`}
                // ignore ts since signature of onClick on react-scroll lib dont receive
                // params but it actually does receive the event object
                // @ts-ignore
                onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) =>
                  handleItemClick(item, e.isTrusted)
                }>
                <Button
                  id={`${PIVOT_PREFIX_ID}${toSnakeCase(item)}-button`}
                  title={getSectionTitle(item)}
                  variant={activeItem === item ? 'alternative' : 'text'}
                  stretched
                  className={clsx(
                    'group justify-start capitalize hover:bg-green-50 hover:!text-theme-primary',
                    {'bg-green-50 !text-theme-primary': activeItem === item},
                  )}
                  classNameText={clsx(
                    'capitalize group-hover:!text-theme-primary',
                    {
                      '!text-theme-primary': activeItem === item,
                    },
                  )}>
                  {getSectionTitle(item)}
                </Button>
              </Link>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default SidebarPivotMenu;
