import {Transition} from '@headlessui/react';
import {Icon} from '@prescriberpoint/ui';
import clsx from 'clsx';
import {FC, ReactNode, useRef} from 'react';
import {useOnClickOutside} from 'usehooks-ts';

interface DesktopShareProps {
  children: ReactNode;
  isOpen: boolean;
  handleClose: () => void;
  renderUnderButton: boolean;
}

const DesktopShare: FC<DesktopShareProps> = ({
  children,
  isOpen,
  renderUnderButton,
  handleClose,
}) => {
  const containerRef = useRef<HTMLDivElement>(null);
  useOnClickOutside(containerRef, handleClose);
  return (
    <div ref={containerRef}>
      {isOpen ? (
        <Transition
          as='div'
          show={isOpen}
          className={clsx([
            'right-0 z-max box-border p-4',
            !renderUnderButton &&
              '-top-2 origin-bottom-right -translate-y-full',
            'absolute mb-2 w-full rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:max-w-[312px]',
          ])}
          enter='transition duration-100 ease-out'
          enterFrom='transform scale-95 opacity-0'
          enterTo='transform scale-100 opacity-100'
          leave='transition duration-75 ease-out'
          leaveFrom='transform scale-100 opacity-100'
          leaveTo='transform scale-95 opacity-0'>
          <div
            id='cancel_share'
            className='absolute right-4 top-5 hover:cursor-pointer'
            onClick={() => handleClose()}>
            <Icon name='Cancel' className='flex h-3 w-3 text-neutral-primary' />
          </div>
          {children}
        </Transition>
      ) : null}
    </div>
  );
};

export default DesktopShare;
