import {Link, Icon, Text, IconButton} from '@prescriberpoint/ui';
import clsx from 'clsx';
import React, {FC, useEffect, useState} from 'react';
import QRCode from 'react-qr-code';
import {NewLinkType, PUBMED_RESOURCE_TYPES} from './ResourceButton';
import {PROTOCOL_HOSTNAME} from '@/constants/global';
import {linkTypes} from '@/constants/resourceButton';
import {formatISOtoString, formatToYearMonth} from '@/utils/date';
import {checkNaming, convertResourceType} from '@/utils/resourceButtonHelpers';

interface ResourceDetailsProps {
  date?: string;
  debounced: (cb: any) => void;
  disabledStyle: boolean;
  handleLinkClick: () => void;
  hasQr: boolean | undefined;
  isQr?: boolean;
  link: any;
  type: NewLinkType;
}

const Qr = ({title, href}: {title?: string; href?: string}) => (
  <span id="code" className="mr-2 bg-white p-1">
    <QRCode
      id="QRCodeScaled"
      size={256}
      style={{height: 'auto', maxWidth: '100%', width: '100%'}}
      qr-title={title ?? 'QR Title'}
      value={href ?? ''}
      // @ts-ignore
      viewBox={`0 0 256 256`}
    />
  </span>
);

const getTextStyle = (disabledStyle: boolean) =>
  disabledStyle ? 'tertiary' : 'secondary';

const copyIconName = (copied: boolean) => (copied ? 'CopyFilled' : 'Copy');
const tooltipText = (copied: boolean) => (copied ? 'Copied!' : 'Copy');

const ResourceDetails: FC<ResourceDetailsProps> = ({
  date = '',
  debounced,
  disabledStyle,
  handleLinkClick,
  hasQr,
  isQr = false,
  link,
  type,
}) => {
  const isPubMedFeature = PUBMED_RESOURCE_TYPES.includes(type);
  const showType = !!type;
  const [copied, setCopied] = useState(false);

  const handleCopyToClipboard = async () => {
    try {
      await navigator.clipboard.writeText(
        `${PROTOCOL_HOSTNAME}/therapies/${link?.href}`,
      );
    } catch (err) {
      console.error('Failed to copy: ', err);
    }
  };

  useEffect(() => {
    if (copied) {
      setTimeout(() => {
        setCopied(false);
      }, 3000);
    }
  }, [copied]);

  return (
    <>
      <span className="flex items-center">
        {hasQr && <Qr title={link.title} href={link.href} />}
        {disabledStyle && isQr && (
          <Icon
            name="Qr"
            className="mr-2 h-6 w-6 items-center justify-center"
          />
        )}
        <span className="flex flex-col items-start" id="labelContainer">
          {!!date && !isPubMedFeature && (
            <Text
              as="body-xs"
              weight="bold"
              casing="caps"
              variant={getTextStyle(disabledStyle)}>
              {`Verified: ${formatISOtoString(date)}`}
            </Text>
          )}
          {showType && isPubMedFeature ? (
            <span className="flex flex-wrap items-center">
              <Text
                as="body-xs"
                weight="bold"
                casing="caps"
                className="text-blue-700"
                variant={getTextStyle(disabledStyle)}>
                {type}
              </Text>
              <span className="mx-1 h-1 w-1 rounded-full bg-neutral-darker/20" />
              {date ? (
                <Text
                  as="body-xs"
                  weight="bold"
                  casing="caps"
                  className="text-blue-700"
                  variant={getTextStyle(disabledStyle)}>
                  {formatToYearMonth(date)}
                </Text>
              ) : null}
            </span>
          ) : null}
          {showType && !isPubMedFeature ? (
            <span className="flex flex-wrap items-center">
              <p
                className={clsx(
                  'm-0 mr-1 h-1 w-1 rounded text-neutral-primary',
                  linkTypes[type],
                )}
              />
              <Text
                as="body-xs"
                weight="bold"
                casing="caps"
                variant={getTextStyle(disabledStyle)}>
                {convertResourceType(type)}
              </Text>
            </span>
          ) : null}
          {!disabledStyle ? (
            <Link
              onClick={() => debounced(handleLinkClick())}
              disabled={disabledStyle}
              rel={isPubMedFeature ? 'nofollow' : undefined}
              linkSize="lg"
              className="flex flex-col capitalize md:flex-row md:items-center md:space-x-2"
              type='secondary'
              label={
                link
                  ? `${checkNaming(link)} ${link.demographic ?? ''}`
                  : 'Oops, something is off'
              }
            />
          ) : (
            <Text
              as="body-sm"
              weight="bold"
              casing="title"
              variant={disabledStyle ? 'tertiary' : 'base'}
              className="qr-title">
              {link ? checkNaming(link) : 'Oops, something is off'}
            </Text>
          )}
        </span>
        {isPubMedFeature ? (
          <IconButton
            id="copy_action"
            onClick={() => {
              if (copied) return;
              debounced(handleCopyToClipboard());
              setCopied(true);
            }}
            className="!h-9 !w-9 !border-0 !bg-transparent -mx-2 -mb-4 opacity-0 transition-opacity duration-300 ease-in-out hover:opacity-100"
            icon={
              <Icon
                className={clsx('h-5 w-5 rounded-full', 'text-theme-primary')}
                name={copyIconName(copied)}
              />
            }
            tooltip={tooltipText(copied)}
          />
        ) : null}
      </span>
      {hasQr && (
        <div
          id="instructions"
          className="flex-1 whitespace-nowrap px-2 text-end">
          <Text as="body-xs" weight="bold" casing="caps" variant="secondary">
            ASK PATIENT TO:
          </Text>
          <br />
          <Text variant="base" as="body-sm" weight="bold" casing="title">
            Open Camera on Phone <br /> Scan QR Code & Tap Link
          </Text>
        </div>
      )}
    </>
  );
};

export default ResourceDetails;
