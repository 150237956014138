import {useMemo} from 'react';
import {MED_AI_CHATBOT} from '@/constants/flags';
import {MED_AI_SET_IDS} from '@/constants/global';
import {useFlag} from '@/context';

export function useShowMedAi(setId: string) {
  const enableMedAi = useFlag(MED_AI_CHATBOT);

  return useMemo(() => {
    const isEnabledForSetId = MED_AI_SET_IDS.includes(setId);
    return enableMedAi && isEnabledForSetId;
  }, [setId, enableMedAi]);
}
