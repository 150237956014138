import {customEvent} from '@prescriberpoint/ui';
import {UseMutationResult} from '@tanstack/react-query';
import {useRouter} from 'next/router';
import {FC, createContext, useContext, useEffect} from 'react';
import {useCookies} from 'react-cookie';
import {CEVENT_USER, DLVUser} from '@/constants';
import {ACCESS_TOKEN_COOKIE, COOKIE_CONSENT_COOKIE} from '@/constants/cookies';
import {useHandleSetCookies} from '@/hooks/useHandleSetCookies';
import useUserData from '@/hooks/useUserData';
import {IRepCompleteSignup, useRepCompleteSignup} from '@/mutations';
import {
  ICheckMagicLinkToLogin,
  useCheckMagicLinkToLogin,
} from '@/mutations/useCheckMagicLinkToLogin';
import {ILogin, LoginDto, useLogin} from '@/mutations/useLogin';
import {IResetPassword, useResetPassword} from '@/mutations/useResetPassword';
import {ISignup, useSignup} from '@/mutations/useSignup';
import {APIError} from '@/services/request';
import {setVariable} from '@/utils/gtm';

export interface UserProfile {
  email?: string | null;
  email_verified?: boolean | null;
  name?: string | null;
  nickname?: string | null;
  picture?: string | null;
  sub?: string | null;
  updated_at?: string | null;
}

interface IUser {
  user?: UserProfile;
  error?: APIError;
  isLoading: boolean;
  isSuccess: boolean;
  login: (e: ILogin) => Promise<any>;
  checkMagicLinkToLogin: (e: ICheckMagicLinkToLogin) => Promise<any>;
  resetPass: (e: IResetPassword) => void;
  signup: (e: ISignup) => Promise<any>;
  logout: () => void;
  repCompleteSignupMutation?: UseMutationResult<
    LoginDto,
    unknown,
    IRepCompleteSignup,
    unknown
  >;
}

const defaultState: IUser = {
  user: {},
  error: undefined,
  isLoading: false,
  isSuccess: false,
  login: () => Promise.resolve(),
  checkMagicLinkToLogin: () => Promise.resolve(),
  resetPass: () => {},
  signup: () => Promise.resolve(),
  logout: () => {},
  repCompleteSignupMutation: undefined,
};

export const AuthContext = createContext<IUser>(defaultState);

export const useAuth = () => useContext(AuthContext);

export const AuthProvider: FC = (props) => {
  const router = useRouter();
  const {setUserData, resetUserData} = useUserData();
  const [_cookies, _setCookie, removeCookie] = useCookies([
    ACCESS_TOKEN_COOKIE,
    COOKIE_CONSENT_COOKIE,
  ]);
  const {handleSetCookie} = useHandleSetCookies();

  const onLoginSuccess = (data: DLVUser) => {
    customEvent<DLVUser>(CEVENT_USER, data);
    const {user} = data;
    setVariable('user', {
      email: user.email,
      npi: user?.npi,
      firstName: user.firstName,
      lastName: user.lastName,
    });
  };

  const loginMutation = useLogin(onLoginSuccess);
  const checkMagicLinkToLoginMutation =
    useCheckMagicLinkToLogin(onLoginSuccess);
  const signupMutation = useSignup();
  const resetPasswordMutation = useResetPassword();
  const repCompleteSignupMutation = useRepCompleteSignup();

  const logout = () => {
    removeCookie(ACCESS_TOKEN_COOKIE, {path: '/'});
    resetUserData();
    loginMutation.reset();
    checkMagicLinkToLoginMutation.reset();
    signupMutation.reset();
    resetPasswordMutation.reset();
    repCompleteSignupMutation.reset();
  };

  const accessToken =
    loginMutation.data?.accessToken ||
    checkMagicLinkToLoginMutation.data?.accessToken ||
    signupMutation.data?.accessToken ||
    resetPasswordMutation.data?.accessToken ||
    repCompleteSignupMutation.data?.accessToken;

  // update user information after successfully login/signup/resetPass
  useEffect(() => {
    if (accessToken) {
      const {firstName, lastName, redirectTo, picture, email} =
        (loginMutation.data ||
          checkMagicLinkToLoginMutation.data ||
          signupMutation.data ||
          resetPasswordMutation.data ||
          repCompleteSignupMutation.data) as LoginDto;

      if (router && redirectTo) {
        router.replace({
          query: {...router.query, redirect: redirectTo},
        });
      }

      handleSetCookie(ACCESS_TOKEN_COOKIE, accessToken, {
        path: '/',
        maxAge: 60 * 60 * 24 * 30, // set max age to 30 days
      });

      setUserData((prevUserInformation) => ({
        ...prevUserInformation,
        givenName: firstName ?? '',
        familyName: lastName ?? '',
        picture: picture ?? '',
      }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    accessToken,
    loginMutation.data,
    checkMagicLinkToLoginMutation.data,
    signupMutation.data,
    resetPasswordMutation.data,
    repCompleteSignupMutation.data,
  ]);

  const values = {
    error: (loginMutation.error ||
      checkMagicLinkToLoginMutation.error ||
      signupMutation.error ||
      resetPasswordMutation.error) as APIError | undefined,
    isLoading:
      loginMutation.isPending ||
      checkMagicLinkToLoginMutation.isPending ||
      signupMutation.isPending ||
      resetPasswordMutation.isPending,
    login: loginMutation.mutateAsync,
    checkMagicLinkToLogin: checkMagicLinkToLoginMutation.mutateAsync,
    signup: signupMutation.mutateAsync,
    resetPass: resetPasswordMutation.mutateAsync,
    isSuccess:
      loginMutation.isSuccess ||
      checkMagicLinkToLoginMutation.isSuccess ||
      signupMutation.isSuccess ||
      resetPasswordMutation.isSuccess,
    logout,
    repCompleteSignupMutation,
  };

  return <AuthContext.Provider value={values} {...props} />;
};
