import {Text, TextCasing} from '@prescriberpoint/ui';
import clsx, {ClassValue} from 'clsx';
import {useRouter} from 'next/router';
import {
  FC,
  ReactNode,
  useEffect,
  useLayoutEffect,
  useCallback,
  useRef,
} from 'react';
import {CTA_SECTIONS_IDS} from '@/constants/ctaServices';
import {SHOW_V2_PPT_DESIGN} from '@/constants/flags';
import {useFlag} from '@/context';
import {useShowFixedCTAMenu} from '@/hooks';

const canUseDOM = typeof window !== 'undefined';
const useIsomorphicLayoutEffect = canUseDOM ? useLayoutEffect : useEffect;

interface ISectionHeaderProps {
  heading?: string;
  title?: string;
  titleCasing?: TextCasing;
  subtitle?: string | ReactNode;
  className?: ClassValue;
}

const SectionHeader: FC<ISectionHeaderProps> = ({
  heading,
  title,
  titleCasing = 'default',
  subtitle,
  className,
}) => {
  const Subtitle = useCallback(() => {
    if (typeof subtitle === 'string') {
      return (
        <Text tag='h4' as='body-md' weight='semibold'>
          {subtitle}
        </Text>
      );
    } else {
      return subtitle;
    }
  }, [subtitle]);

  return (
    <div className={clsx('flex flex-col gap-y-2 sm:gap-y-3', className)}>
      {heading ? (
        <Text tag='h3' variant='blue' casing='caps'>
          {heading}
        </Text>
      ) : null}
      {title ? (
        <Text
          tag='h2'
          as='headline-md'
          weight={heading ? 'semibold' : 'bold'}
          casing={titleCasing}>
          {title}
        </Text>
      ) : null}
      {subtitle ? <Subtitle /> : null}
    </div>
  );
};

export interface ISectionProps {
  children: React.ReactNode;
  id: string;
  heading?: string;
  title?: string;
  subtitle?: string | ReactNode;
  titleCasing?: TextCasing;
  className?: ClassValue;
  headerClassName?: ClassValue;
}

const Section: FC<ISectionProps> = ({
  id,
  heading,
  title,
  titleCasing,
  subtitle,
  className,
  headerClassName,
  children,
}) => {
  const router = useRouter();
  const ref = useRef<HTMLDivElement>(null);
  const showCTAFixedMenu = useShowFixedCTAMenu();
  const showNewDesign = useFlag(SHOW_V2_PPT_DESIGN);

  useIsomorphicLayoutEffect(() => {
    if (router?.query?.help) {
      const sectionId = CTA_SECTIONS_IDS[router.query.help as string];
      const element = ref.current;
      if (element?.id === sectionId) {
        //to make sure all elemnts are loaded, otherwise section might be missplaced
        const timeout = setTimeout(() => {
          if (showCTAFixedMenu) {
            const anchor = router?.asPath?.split('#')[1];
            document.getElementById(`ss_pivot_${anchor}`)?.click();
          } else {
            element.scrollIntoView();
          }
        }, 500);
        return () => clearTimeout(timeout);
      }
    }
  }, [router]);

  return (
    <div
      ref={ref}
      id={id}
      data-testid={id}
      className={clsx(
        'scroll-mt-8 space-y-8',
        showNewDesign
          ? 'py-8 md:py-12'
          : 'pt-8 first:pt-0 md:pt-14 first:md:pt-0',
        className,
      )}>
      {heading || title ? (
        <SectionHeader
          heading={heading}
          title={title}
          titleCasing={titleCasing}
          subtitle={subtitle}
          className={headerClassName}
        />
      ) : null}
      <div
        className={clsx('space-y-4', {
          'md:p-4': !showNewDesign,
        })}>
        {children}
      </div>
    </div>
  );
};

export default Section;
