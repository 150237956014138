import {Text} from '@prescriberpoint/ui';
import clsx, {ClassValue} from 'clsx';
import {LucideIcon} from 'lucide-react';
import {FC} from 'react';

interface IChipPdpProps {
  label: string;
  Icon?: LucideIcon;
  className?: ClassValue;
  classNameIcon?: ClassValue;
  onClick?: () => void;
}

const ChipPdp: FC<IChipPdpProps> = ({
  label,
  Icon,
  className,
  classNameIcon,
  onClick = () => {},
}) => (
  <button
    onClick={onClick}
    className={clsx(
      'flex items-center justify-between space-x-1 rounded-3xl border border-solid border-neutral-quaternary px-3 py-2',
      className,
    )}>
    {Icon ? <Icon size={16} className={clsx(classNameIcon)} /> : null}
    <Text as='overline-xs' className='!text-neutral-primary'>
      {label}
    </Text>
  </button>
);

export default ChipPdp;
