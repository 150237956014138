export const ENABLED_DRUG_SUMMARIES = [
  'ee06186f-2aa3-4990-a760-757579d8f77b',
  'adec4fd2-6858-4c99-91d4-531f5f2a2d79',
];

export const DRUG_SUMMARIES: {[setId: string]: string} = {
  'ee06186f-2aa3-4990-a760-757579d8f77b': `
**Wegovy (semaglutide) Dosage Information:**

| **Indication**                       | **Dosage Form**           | **Strength**                 |
|--------------------------------------|---------------------------|------------------------------|
| Chronic Weight Management (Adults)   | Injection (subcutaneous)  | 0.25 mg, 0.5 mg, 1 mg, 1.7 mg, 2.4 mg |

---

**Wegovy** (semaglutide) is FDA-approved for **chronic weight management** in adults who have obesity (BMI ≥30) or are overweight (BMI ≥27) with at least one weight-related condition, such as high blood pressure, Type 2 diabetes, or high cholesterol. Wegovy works by mimicking a hormone called GLP-1 that regulates appetite and food intake, helping patients reduce calorie consumption and achieve sustained weight loss.

Common **side effects** of Wegovy include nausea, diarrhea, vomiting, constipation, and abdominal pain. In some cases, more serious side effects like pancreatitis, gallbladder problems, or allergic reactions can occur.

Wegovy is administered as a weekly subcutaneous injection. It is part of a comprehensive weight management program that includes diet and exercise.

For full prescribing information, consult the FDA label in the [**Prescribing Information section**](#drug_label) below.
`,

  'adec4fd2-6858-4c99-91d4-531f5f2a2d79': `
**Ozempic (semaglutide) Dosage Information:**

| **Indication**                       | **Dosage Form**           | **Strength**                 |
|--------------------------------------|---------------------------|------------------------------|
| Type 2 Diabetes Mellitus (Adults)    | Injection (subcutaneous)  | 0.25 mg, 0.5 mg, 1 mg, 2 mg  |

---

**Ozempic** (semaglutide) is an FDA-approved medication used to help manage **Type 2 diabetes** in adults. It helps control blood sugar by mimicking a hormone called GLP-1, which stimulates insulin production when blood sugar levels are high. This action helps improve glycemic control in people with Type 2 diabetes.

Common **side effects** of Ozempic include nausea, vomiting, diarrhea, abdominal pain, and constipation. Some patients may also experience more serious side effects such as pancreatitis, changes in vision, or allergic reactions.

Ozempic is administered once weekly as a subcutaneous injection. It is not approved for weight loss, although some patients may experience weight loss as a secondary benefit while using the medication to manage their diabetes.

For full prescribing information, consult the FDA label in the [**Prescribing Information section**](#drug_label) below..
`,
};
