import {QueryClient, useQuery} from '@tanstack/react-query';
import {IPrefetchedQuery} from './prefetchQueries';
import {EnhancedContentDto} from '@/models';
import {read} from '@/services/api';

export async function getEnhancedContent(
  setId: string,
): Promise<EnhancedContentDto | null> {
  let data = null;

  if (!setId) return data;

  try {
    data = await read<EnhancedContentDto>(
      'enhancedContent',
      {parentName: 'contentful', resourceId: setId},
      {},
      'nextApi',
    );
  } catch (e) {
    console.error(e);
  }
  return data;
}

export function useEnhancedContent(id: string, options = {}) {
  return useQuery<EnhancedContentDto | null>({
    queryKey: ['enhanced-content', id],
    queryFn: () => getEnhancedContent(id),
    ...options,
  });
}

export async function fetchQueryEnhancedContent(
  setId: string,
  queryClient: QueryClient,
) {
  return await queryClient.fetchQuery({
    queryKey: ['enhanced-content', setId],
    queryFn: async () => await getEnhancedContent(setId),
  });
}

export function getEnhancedContentPrefetchQuery(id: string): IPrefetchedQuery {
  return {
    queryKey: ['enhanced-content', id],
    getFn: async () => await getEnhancedContent(id),
  };
}
