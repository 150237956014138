import {NECESSARY_COOKIES} from '@/constants/cookies';

export function removeAllCookies() {
  const allCookies = document.cookie.split(';');

  allCookies.forEach((cookie) => {
    const cookieName = cookie.split('=')[0].trim();

    if (!NECESSARY_COOKIES.includes(cookieName)) {
      // Set the cookie's expiration date to a past date to remove it
      document.cookie = `${cookieName}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
    }
  });
}

export const hasDoNotTrackEnabled = () =>
  typeof window !== 'undefined'
    ? navigator?.doNotTrack === '1' || // For most browsers
      window?.doNotTrack === '1' || // For IE 11 and older
      //@ts-ignore
      navigator?.msDoNotTrack === '1'
    : false; // For older Microsoft browsers

export const hasGlobalPrivacyControlEnabled = () =>
  typeof window !== 'undefined'
    ? //@ts-ignore
      navigator?.globalPrivacyControl === true
    : false;
