import {Link, Text} from '@prescriberpoint/ui';
import {FC} from 'react';
import Icon from '../Icon';

export interface IAffordabilityIncomeCtaProps {
  guidelinesText?: string;
}

const AffordabilityIncomeCta: FC<IAffordabilityIncomeCtaProps> = ({
  guidelinesText = 'Compare income to federal poverty guidelines to check eligibility',
}) => (
  <div className='flex gap-x-3 rounded-xl border border-solid border-neutral-light bg-white p-4'>
    <div className='flex size-10 shrink-0 items-center justify-center rounded-full bg-green-50'>
      <Icon name='dollar-sign' size={24} className='text-theme-primary' />
    </div>
    <div className='flex flex-col gap-y-1'>
      <Text as='body-md'>{guidelinesText}</Text>
      <Link
        label='See guidelines'
        type='underline'
        target='_blank'
        href='https://aspe.hhs.gov/topics/poverty-economic-mobility/poverty-guidelines'
      />
    </div>
  </div>
);

export default AffordabilityIncomeCta;
