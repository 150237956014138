import {useQuery, QueryClient} from '@tanstack/react-query';
import {IPrefetchedQuery} from './prefetchQueries';
import {ProductDto} from '@/models';
import {read} from '@/services/api';

const DUPIXENT_SET_ID = '595f437d-2729-40bb-9c62-c8ece1f82780';
const DUPIXENT_FILTERED_NAME = 'DUPIXENT 100 MG in 0.67 ML Prefilled Syringe';

export async function getLabel(setId: string): Promise<ProductDto | null> {
  let data = null;

  if (!setId) return data;

  try {
    data = await read<ProductDto>('labels', {resourceId: setId}, {}, 'nextApi');
    // Temporary fix for dupixent only
    if (data.id === DUPIXENT_SET_ID) {
      data = {
        ...data,
        prescribableNames: data?.prescribableNames?.filter(
          (n) => n !== DUPIXENT_FILTERED_NAME,
        ),
      };
    }
  } catch (e) {
    console.error(e);
  }

  return data;
}

export function useLabel(setId: string, options = {}) {
  return useQuery<ProductDto | null>({
    queryKey: ['label', setId],
    queryFn: () => getLabel(setId),
    ...options,
  });
}

export function getLabelPrefetchQuery(setId: string): IPrefetchedQuery {
  return {
    queryKey: ['label', setId],
    getFn: async () => await getLabel(setId),
  };
}

export async function fetchQueryLabel(setId: string, queryClient: QueryClient) {
  return await queryClient.fetchQuery({
    queryKey: ['label', setId],
    queryFn: () => getLabel(setId),
  });
}
