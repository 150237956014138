import {Text} from '@prescriberpoint/ui';
import {FC} from 'react';
import RegistrationModal from '@/components/RegistrationModal';

const FavoriteStep: FC = () => (
  <>
    <Text as='title-lg' weight='extrabold' className='mb-3 block'>
      Favorite
    </Text>
    <Text as='body-md' className='mb-5 block'>
      Save this brand and other FDA-approved drug labels to view later. <br />
      <br />
      Create an account so you can save your favorites
    </Text>
    <RegistrationModal onlyContent={!!true} />
  </>
);

export default FavoriteStep;
