import {Cookies} from 'react-cookie';
import {
  APIError,
  IAPIResponse,
  IErrorAttributes,
  IRequestParams,
  requestOptions,
  requestPath,
} from './request';
import {ACCESS_TOKEN_COOKIE, SPLIT_USER_COOKIE} from '@/constants/cookies';
import {
  API_BASE_URL,
  APIM_SUBSCRIPTION_KEY,
  SEARCH_FORMS_URL,
  PRESCRIBABILITY_API_URL,
  PAGES_API_URL_SERVER,
  ENHANCED_CONTENT_API_URL_SERVER,
  PROFILE_API_URL_SERVER,
  PROTOCOL_HOSTNAME,
  AZURE_STORAGE_LABELS_ENDPOINT,
  CDN_URL,
  CDN_URL_SERVER,
} from '@/constants/global';

const cookies = new Cookies();

export const SERVICE_URLS = {
  pages: 'pages/api/',
  enhanced: 'enhancedcontent/api/',
  nextApi: PROTOCOL_HOSTNAME + '/api',
  search: SEARCH_FORMS_URL,
  profile: 'profile/api/',
  graph: 'profile/graph/',
  prescribability: PRESCRIBABILITY_API_URL, // TODO: remove everything related to prescribability
  samples: 'samples/api/',
  drugLabelsStorage: AZURE_STORAGE_LABELS_ENDPOINT,
  cdn: CDN_URL,
};

export const SERVICE_URLS_SERVER = {
  ...SERVICE_URLS,
  // SERVICES NAMES
  pages: PAGES_API_URL_SERVER,
  enhanced: ENHANCED_CONTENT_API_URL_SERVER,
  profile: PROFILE_API_URL_SERVER,
  nextApi: PROTOCOL_HOSTNAME + '/api',
  drugLabelsStorage: AZURE_STORAGE_LABELS_ENDPOINT,
  cdn: CDN_URL_SERVER,
};

export const SERVICE_DEFAULT_VERSIONS = {
  drugLabelsStorage: 1,
  pages: 2,
  enhanced: 1,
  nextApi: 1,
  search: 1,
  profile: 1,
  graph: 1,
  prescribability: 1,
  samples: 1,
  cdn: 1,
};

const UNVERSIONED_SERVICES = ['drugLabelsStorage', 'cdn'];
const NON_APIM_SERVICES = [
  'nextApi',
  'search',
  'prescribablitity',
  'drugLabelsStorage',
  'cdn',
];

// Services without encoded url support
const DECODED_URLS = ['drugsInteraction'];

export type ServiceUrl = keyof typeof SERVICE_URLS;

export const SERVICES_URLS_KEYS = Object.keys(SERVICE_URLS).reduce(
  (acc, cur) => ((acc[cur as ServiceUrl] = cur), acc),
  {} as typeof SERVICE_URLS,
);

async function api<T>(
  path: string,
  options: RequestInit = {},
  service: ServiceUrl,
  apiVersion?: number,
  expectErrorNumber?: number,
  bypassServer?: boolean,
  apimMockStatusCode?: number,
): Promise<IAPIResponse<T>> {
  const fingerprint = cookies.get(SPLIT_USER_COOKIE);

  const apimHeaders = NON_APIM_SERVICES.includes(service)
    ? undefined
    : {
        'Ocp-Apim-Subscription-Key': APIM_SUBSCRIPTION_KEY,
        'Ocp-Apim-Client-Key': fingerprint ? `${fingerprint}` : undefined,
        'Ocp-Apim-Client-Mock': apimMockStatusCode
          ? `${apimMockStatusCode}`
          : undefined,
      };

  const headers = {
    ...(options.headers ?? {}),
    'Content-Type': 'application/json; charset=utf-8',
    Accept: 'application/json',
    ...apimHeaders,
  };

  let targetUrl: {
    href: string;
  };

  const isClientSide = typeof window !== 'undefined';

  let serviceUrl =
    isClientSide || bypassServer
      ? SERVICE_URLS[service]
      : SERVICE_URLS_SERVER[service];

  // if we are in client side, we check if the service is in apim or not so we can modify the service url
  if ((isClientSide || bypassServer) && !NON_APIM_SERVICES.includes(service)) {
    serviceUrl = API_BASE_URL + SERVICE_URLS[service];
  }

  if (service === 'nextApi') {
    targetUrl = {
      href: `${serviceUrl}/${path}`,
    };
  } else {
    let base = UNVERSIONED_SERVICES.includes(service)
      ? serviceUrl
      : serviceUrl + `v${apiVersion ?? SERVICE_DEFAULT_VERSIONS[service]}/`;
    targetUrl = new URL(path, base);
  }

  if (DECODED_URLS.includes(service)) {
    targetUrl.href = decodeURIComponent(targetUrl.href);
  }

  try {
    const response = await fetch(targetUrl.href, {
      ...options,
      headers,
    });
    let data = {};
    let text = '';

    try {
      const text = await response.text();
      data = text.trim() ? JSON.parse(text.trim()) : {};
    } catch (e: any) {
      console.error(e);
      //@ts-ignore
      if (typeof window !== 'undefined' && window.newrelic?.noticeError) {
        const errorAtributes: IErrorAttributes = {
          url: targetUrl.href,
          status: response.status,
          data: data,
          errorSource: 'APIParseError',
          headers,
          fingerprint,
        };
        //@ts-ignore
        window.newrelic?.noticeError(
          new Error(`ParseError: ${e.code} - ${e.message || 'unknown error'}`),
          {
            text,
            textLength: text?.length,
            ...errorAtributes,
          },
        );
      }
    }

    if (!response.ok && response.status !== expectErrorNumber) {
      const errorAtributes: IErrorAttributes = {
        url: targetUrl.href,
        status: response.status,
        data: data,
        errorSource: 'APIError',
        headers,
        fingerprint,
      };

      if (typeof window === 'undefined') {
        import('./logger').then(({logger}) => {
          logger.error(`APIError ${response.status}`, errorAtributes);
        });
      } else {
        //@ts-ignore
        if (window.newrelic?.noticeError) {
          //@ts-ignore
          window.newrelic?.noticeError(
            new Error(`ApiError ${response.status}`),
            errorAtributes,
          );
        }
      }
      throw new APIError(response.status, data);
    }

    return {
      ok: response.ok,
      status: response.status,
      ...(data.hasOwnProperty('data') ? data : {data}),
    } as IAPIResponse<T>;
  } catch (e) {
    if (!(e instanceof APIError)) {
      //@ts-ignore
      if (typeof window !== 'undefined' && window.newrelic?.noticeError) {
        //@ts-ignore
        window.newrelic?.noticeError(e, {
          url: targetUrl.href,
          errorSource: 'APIFetchError',
          headers,
          fingerprint,
        });
      }
    }
    throw e;
  }
}

async function request<T>(
  resourceName: string,
  requestParams: IRequestParams,
  options: RequestInit,
  service: ServiceUrl,
  withAuth?: boolean,
  apiVersion?: number,
  expectErrorNumber?: number,
  bypassServer?: boolean,
  apimMockStatusCode?: number,
): Promise<T> {
  // TODO: this does not support athenticated requests on SSR
  const accessToken = cookies.get(ACCESS_TOKEN_COOKIE); // new auth flow

  if (withAuth && accessToken) {
    if (options.headers) {
      Object.assign(options.headers, {
        Authorization: `Bearer ${accessToken}`,
      });
    } else {
      options['headers'] = {
        Authorization: `Bearer ${accessToken}`,
      };
    }
  }
  const {data} = await api<T>(
    requestPath(resourceName, requestParams, options),
    requestOptions(requestParams, options),
    service,
    apiVersion,
    expectErrorNumber,
    bypassServer,
    apimMockStatusCode,
  );
  return data;
}

export function read<T>(
  resourceName: string,
  requestParams: IRequestParams = {},
  options: RequestInit = {},
  service: ServiceUrl,
  withAuth?: boolean,
  apiVersion?: number,
  expectErrorNumber?: number,
  bypassServer?: boolean,
  apimMockStatusCode?: number,
): Promise<T> {
  return request<T>(
    resourceName,
    requestParams,
    {
      ...options,
      method: 'GET',
    },
    service,
    withAuth,
    apiVersion,
    expectErrorNumber,
    bypassServer,
    apimMockStatusCode,
  );
}

export function create<T>(
  resourceName: string,
  requestParams: IRequestParams = {},
  options: RequestInit = {},
  service: ServiceUrl,
  withAuth?: boolean,
  apiVersion?: number,
  expectErrorNumber?: number,
  bypassServer?: boolean,
  apimMockStatusCode?: number,
): Promise<T> {
  return request<T>(
    resourceName,
    requestParams,
    {
      ...options,
      method: 'POST',
    },
    service,
    withAuth,
    apiVersion,
    expectErrorNumber,
    bypassServer,
    apimMockStatusCode,
  );
}

export function update<T>(
  resourceName: string,
  requestParams: IRequestParams = {},
  options: RequestInit = {},
  service: ServiceUrl,
  withAuth?: boolean,
  apiVersion?: number,
  expectErrorNumber?: number,
  bypassServer?: boolean,
  apimMockStatusCode?: number,
): Promise<T> {
  return request<T>(
    resourceName,
    requestParams,
    {
      method: 'PATCH',
      ...options,
    },
    service,
    withAuth,
    apiVersion,
    expectErrorNumber,
    bypassServer,
    apimMockStatusCode,
  );
}

export function destroy<T>(
  resourceName: string,
  requestParams: IRequestParams = {},
  options: RequestInit = {},
  service: ServiceUrl,
  withAuth?: boolean,
  apiVersion?: number,
  expectErrorNumber?: number,
  bypassServer?: boolean,
  apimMockStatusCode?: number,
): Promise<T> {
  return request<T>(
    resourceName,
    requestParams,
    {
      ...options,
      method: 'DELETE',
    },
    service,
    withAuth,
    apiVersion,
    expectErrorNumber,
    bypassServer,
    apimMockStatusCode,
  );
}

export const HTTP_METHODS = {
  GET: read,
  POST: create,
  PUT: update,
  PATCH: update,
  DELETE: destroy,
};

export type HttpMethod = keyof typeof HTTP_METHODS;
