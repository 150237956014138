import {ClassValue, clsx} from 'clsx';
import {ArrowUpIcon} from 'lucide-react';
import {FC} from 'react';
import {animateScroll} from 'react-scroll';

export interface IBackToTopButtonProps {
  className?: ClassValue;
  show?: boolean;
}

const BackToTopButton: FC<IBackToTopButtonProps> = ({
  className,
  show = false,
}) => {
  const handleGoToTheTop = () => {
    animateScroll.scrollToTop();
  };

  if (!show) {
    return null;
  }

  return (
    <button
      className={clsx(
        'flex items-center justify-center rounded-full bg-neutral-primary/12 p-[10px]',
        className,
      )}
      onClick={handleGoToTheTop}>
      <ArrowUpIcon
        data-testid='arrow-up-icon'
        size={24}
        className='text-neutral-primary'
      />
    </button>
  );
};

export default BackToTopButton;
