import {TriangleAlertIcon} from 'lucide-react';
import {FC, useCallback, useMemo} from 'react';
import {Link} from 'react-scroll';
import ChipPdp from '@/components/ChipPdp';
import {useBlackBoxWarningContext} from '@/context/BlackBoxWarningContext';
import {useCurrentSlug} from '@/hooks';
import {useLabel} from '@/queries';

const BoxedWarning: FC = () => {
  const {setIdxOpened} = useBlackBoxWarningContext();
  const {setId} = useCurrentSlug();
  const {data: product} = useLabel(setId);

  const hasWarning = useMemo(() => product?.label?.boxedWarning, [product]);

  const handleLinkClick = useCallback(() => {
    setIdxOpened(0);
  }, [setIdxOpened]);

  if (!hasWarning) {
    return null;
  }

  return (
    <Link
      to='boxed_warning'
      className='cursor-pointer'
      offset={-48}
      smooth='easeInOutQuart'
      duration={1000}
      onClick={handleLinkClick}>
      <ChipPdp
        onClick={handleLinkClick}
        label='BOXED WARNING'
        Icon={TriangleAlertIcon}
        classNameIcon='!text-error'
      />
    </Link>
  );
};

export default BoxedWarning;
