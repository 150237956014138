import {useMutation} from '@tanstack/react-query';
import {create} from '@/services/api';

export enum RegistrationProcessEnum {
  SampleOrdering = 'SampleOrdering',
  Registration = 'Registration',
  RegistrationAi = 'RegistrationAi',
  Invite = 'Invite',
}

export type RegistrationProcessType = keyof typeof RegistrationProcessEnum;

export interface IAddEmail {
  email: string;
  redirectUrl?: string;
  process?: RegistrationProcessType;
}

async function addEmail(params: IAddEmail) {
  return await create(
    'Register',
    {
      parentName: 'Auth',
      params,
    },
    {},
    'profile',
  );
}

export function useAddEmail() {
  return useMutation({mutationFn: addEmail});
}
