export const MOST_VIEW_RESOURCES_SECTION = 'ppt_most_view_resources_section';
export const MEDICAL_SCIENCE_LIAISON_SECTION =
  'ppt_medical_science_liason_section';
export const REPORT_ADVERSE_EVENT_SECTION = 'ppt_report_adverse_event_section';
export const PRIOR_AUTHORIZATION_SECTION = 'ppt_prior_authorization_section';
export const FINANCIAL_ASSISTANCE_SUPPORT_SECTION =
  'ppt_financial_assistance_support_section';
export const PATIENT_EDUCATION_SECTION = 'ppt_patient_education_section';
export const REQUEST_SAMPLES_SECTION = 'ppt_request_samples_section';
export const PHARMACY_SECTION = 'ppt_pharmacy_section';
export const BIOMARKER_SECTION = 'ppt_efficacy_section';
export const INVITE_COWORKER_SHAREABLE_LINK =
  'ppt_invite_coworker_shareable_link';
export const REPS_SECTION = 'ppt_reps_section';
export const CASE_MANAGEMENT = 'samples_cases';
export const PEOPLE_ALSO_ASK_SECTION = 'ppt_people_also_ask_section';
export const SPECIALTY_PHARMACIES_LOGO = 'ppt_specialty_pharmacies_logo';
export const SPECIALTY_PHARMACIES_ENROLL_SECTION =
  'ppt_specialty_pharmacies_enroll_section';
export const ONLY_HCPS_DISCLAIMER = 'ppt_only_hcps_disclaimer';
export const TERMS_AND_CONDITIONS = 'ppt_terms_and_conditions';
export const SHOW_TOGGLE_NOTES = 'ppt_show_toggle_notes';
export const SIGNUP_DRUG_UPDATE = 'ppt_sign_up_drug_update';
export const SHOW_COOKIE_POLICY_IN_FOOTER = 'ppt_show_cookie_policy_in_footer';
export const DISTRIBUTION_PHARMACY_PAGE = 'ppt_specialty_pharmacies_pages';
export const PA_RECENT_FORMS = 'ppt_pa_recent_forms';
export const USE_EMAIL_FOR_REP_REGISTER_OTP_VALIDATION =
  'ppt_use_email_for_rep_register_otp_validation';
export const BIOMARKER_TESTING = 'ppt_biomarker_testing';
export const RESOURCE_PAYWALL_ON_XELJANZ = 'ppt_resource_paywall_on_xeljanz';
export const SHOW_AI_CHATBOT_LINK = 'ppt_show_ai_chatbot_link';
export const FINANCIAL_ASSISTANCE_AFFORDABILITY =
  'ppt_financial_assistance_affordability';
export const SAMPLES_APP = 'ppt_samples_app';
export const AFFORDABILITY_ON_PDP = 'ppt_affordability_pdp';
export const COVERAGE_ON_PDP = 'ppt_coverage_on_pdp';
export const PPT_AI_COMPONENT = 'ppt_ai_webcomponent';
export const HIDE_CHATBOT_FOR_NO_ENHANCED = 'ppt_ai_webcomponent_enhanced';
export const PUB_MED_ARTICLES = 'ppt_pub_med_articles';
export const ADOBE_CDP_SCRIPT = 'ppt_adobe_cdp_script';
export const COVERAGE_FOR_CONSTELLATION = 'ppt_coverage_for_constellation';
export const DDI_TOOL = 'ppt_ddi_tool';
export const PA_BY_CARRIER_FILTER = 'ppt_pa_by_carrier_filter';
export const SHOW_CM_OR_RS_BUTTON =
  'ppt_show_contact_manufacturer_or_request_samples_button';
export const SHOW_V2_PPT_DESIGN = 'ppt_show_v2_ppt_design';
export const HEADER_REMS_INFO = 'ppt_header_rems_info';
export const DDI_MULTI_DRUGS = 'ppt_ddi_multi_drugs';
export const HIDE_CTA_FIXED_MENU = 'ppt_hide_cta_fixed_menu';
export const SHOW_NEW_DDI = 'ppt_new_ddi';
export const MEDICAL_SUBPAGE = 'ppt_medical_subpage';
export const DDI_INGREDIENTS_BRAND_NAMES = 'ppt_ddi_ingredients_brand_names';
export const DDI_BUTTON_TEXT_EXPERIMENT = 'ppt_ddi_cta_text_experiment';
export const REQUEST_SAMPLES_HOME = 'ppt_request_samples_home';
export const COVERAGE_PAGE = 'ppt_coverage_page';
export const NEW_SIDEBAR = 'ppt_new_sidebar';
export const USER_IDENTIFICATION_SURVEY = 'ppt_user_identification_survey';
export const AFFORDABILITY_PDP_SECTION = 'ppt_affordability_pdp_section';
export const AFFORDABILITY_LANDPAGE = 'ppt_affordability_landpage';
export const COVERAGE_V3 = 'ppt_coverage_v3';
export const MCO_QUICK_PICKS = 'ppt-mco-picks';
export const MED_AI_CHATBOT = 'ppt_med_ai_chatbot';
export const CONTENT_SUMMARIES = 'ppt_content_summaries';
export const NEW_HEADER = 'ppt_new_header';
