import {Text} from '@prescriberpoint/ui';
import {FC} from 'react';
import RemsInfo from '../ChipSection/RemsInfo';

const RemsStep: FC = () => (
  <>
    <Text as='title-lg' weight='extrabold'>
      Rems Info
    </Text>
    <RemsInfo />
  </>
);

export default RemsStep;
