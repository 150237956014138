import {customEvent, Tooltip, ButtonIcon} from '@prescriberpoint/ui';
import clsx from 'clsx';
import {Share2} from 'lucide-react';
import {useRouter} from 'next/router';
import {FC, useCallback, useState} from 'react';
import {Bookmark, Share} from '@/components';
import {CEVENT_SHARE_BRAND_PAGE} from '@/constants';
import {PROTOCOL_HOSTNAME} from '@/constants/global';

interface HeroIconsProps {
  drugName: string;
  shareClassName?: string;
}

const HeroIcons: FC<HeroIconsProps> = ({shareClassName, drugName}) => {
  const [isShareOpen, setIsShareOpen] = useState(false);
  const router = useRouter();

  const handleShareClick = useCallback(() => {
    customEvent(CEVENT_SHARE_BRAND_PAGE, {
      brand: drugName,
    });
    setIsShareOpen(true);
  }, [drugName]);

  return (
    <div className='relative'>
      <Bookmark id='summary_like' data-testId='summary_like' />
      <Tooltip className='!left-auto right-0 !translate-x-0' content='Share'>
        <ButtonIcon
          id='summary_share'
          data-testid='summary_share'
          Icon={Share2}
          onClick={handleShareClick}
          variant='default'
          size='md'
        />
      </Tooltip>
      <div
        className={clsx(
          'absolute right-0 top-11 sm:w-[320px]',
          shareClassName,
        )}>
        <Share
          id='summary_share_info'
          isShareOpen={isShareOpen}
          handleClose={() => setIsShareOpen(false)}
          renderUnderButton
          shareLink={`${PROTOCOL_HOSTNAME}${router.asPath}`}
          resource={drugName}
          drugName={drugName}
          section='share'
        />
      </div>
    </div>
  );
};

export default HeroIcons;
