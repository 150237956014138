import {MenuListItem} from '@prescriberpoint/ui';
import {FC} from 'react';
import {Step, REMS_STEP_NUMBER} from '.';

interface IBaseSteps {
  steps: Step[];
  onClick: (value: number) => void;
  showRems: boolean;
}

const BaseStep: FC<IBaseSteps> = ({showRems, steps, onClick}) =>
  steps.map((item: Step) => {
    const showItem =
      item.value > 1 || (showRems && item.value === REMS_STEP_NUMBER);
    return (
      <>
        {showItem ? (
          <button
            key={item.value}
            className='w-full bg-transparent'
            onClick={() => onClick(item.value)}>
            <MenuListItem
              content={item.name}
              contentClassName='text-neutral-primary !pl-0 capitalize'
            />
          </button>
        ) : null}
      </>
    );
  });

export default BaseStep;
