import clsx from 'clsx';
import {FC, ReactNode} from 'react';
import * as FLAGS from '@/constants/flags';
import {useFlag} from '@/context';
import {useCurrentSlug, useEnhanced} from '@/hooks';
interface ContentLayoutProps {
  children: ReactNode;
}

const PageContentLayout: FC<ContentLayoutProps> = ({children}) => {
  const showNewDesign = useFlag(FLAGS.SHOW_V2_PPT_DESIGN);
  const {setId} = useCurrentSlug();
  const {newHeaderAvailable} = useEnhanced(setId);
  const showNewHeader = useFlag(FLAGS.NEW_HEADER) && newHeaderAvailable;

  return (
    <div
      className={clsx(
        'relative mx-auto max-w-[1440px] md:mb-4',
        {'md:px-2': !showNewHeader},
        showNewDesign
          ? 'bg-neutral-lighter-alt'
          : 'bg-neutral-white md:flex md:space-x-9',
      )}>
      {children}
    </div>
  );
};

export default PageContentLayout;
