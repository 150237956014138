import {Button} from '@prescriberpoint/ui';
import clsx from 'clsx';
import {FC} from 'react';
import {animateScroll} from 'react-scroll';

interface IOverviewLinkProps {
  activeItem: boolean;
}

const OverviewLink: FC<IOverviewLinkProps> = ({activeItem}) => (
  <Button
    id='sidebar-overview-button'
    onClick={() => animateScroll.scrollToTop()}
    variant={activeItem ? 'alternative' : 'text'}
    stretched
    className={clsx(
      'group justify-start capitalize hover:bg-green-50 hover:!text-theme-primary',
      {'bg-green-50 !text-theme-primary': activeItem},
    )}
    classNameText={clsx('capitalize group-hover:!text-theme-primary', {
      '!text-theme-primary': activeItem,
    })}>
    Overview
  </Button>
);
export default OverviewLink;
