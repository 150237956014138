import {MenuListItem, Modal, Text, customEvent} from '@prescriberpoint/ui';
import clsx from 'clsx';
import {FC, useMemo, useRef} from 'react';
import {useToggle, useOnClickOutside} from 'usehooks-ts';
import DesktopShare from '../../Share/DesktopShare';
import ChipPdp from '@/components/ChipPdp';
import {CEVENT_CHECK_DOSAGES_AND_STRENGTHS} from '@/constants';
import {useUserAgentContext} from '@/context';
import {useCurrentSlug} from '@/hooks';
import {useLabel} from '@/queries';
import {sortedAlpha} from '@/utils';

const FILTER_DOSAGES_SBD = 'SBD';

const Dosages: FC = () => {
  const [toggledDosages, toggleDosages] = useToggle(false);
  const {setId} = useCurrentSlug();
  const {data: product} = useLabel(setId);
  const containerRef = useRef(null);

  const clickOutsideDesktopHandler = () => {
    if (!isMobileOrTablet && toggledDosages) {
      toggleDosages();
    }
  };

  useOnClickOutside(containerRef, clickOutsideDesktopHandler);

  const dosages = useMemo(() => {
    if (product?.prescribableNames?.length) {
      return product.prescribableNames.sort((a, b) => sortedAlpha(a, b));
    }

    // Fallback to product.names if prescribableNames is not available or is empty
    return (
      product?.names
        ?.filter((name) => name.tty === FILTER_DOSAGES_SBD)
        .map((name) => name.name) ?? []
    );
  }, [product?.names, product?.prescribableNames]);

  const {isMobileOrTablet} = useUserAgentContext();
  const handleOnClickMoreDosages = () => {
    customEvent(CEVENT_CHECK_DOSAGES_AND_STRENGTHS);
    toggleDosages();
  };

  const renderContent = () => (
    <div>
      <Text as='title-lg' weight='extrabold' className='mb-3 block md:hidden'>
        Dosage Forms & Strengths
      </Text>
      {dosages.map((dosage: string) =>
        isMobileOrTablet ? (
          <MenuListItem
            content={dosage}
            key={`dosage-list-${dosage}`}
            contentClassName={clsx('pl-0 text-neutral-primary capitalize')}
          />
        ) : (
          <Text
            key={`dosage-list-${dosage}`}
            as='body-md'
            className='block py-2 capitalize'>
            {dosage}
          </Text>
        ),
      )}
    </div>
  );

  if (dosages.length === 0) {
    return;
  }

  return (
    <div className='relative'>
      <ChipPdp label='DOSAGE & STRENGTHS' onClick={handleOnClickMoreDosages} />
      {toggledDosages ? (
        <>
          <div className='absolute right-0 top-11 md:min-w-[500px]'>
            <DesktopShare
              isOpen={!isMobileOrTablet}
              handleClose={toggleDosages}
              renderUnderButton>
              {renderContent()}
            </DesktopShare>
          </div>
          <Modal
            isOpen={isMobileOrTablet}
            closeModal={toggleDosages}
            showCloseButton
            position='side'
            showConfirm={false}
            showCancel={false}>
            {renderContent()}
          </Modal>
        </>
      ) : null}
    </div>
  );
};

export default Dosages;
