import {IComboBoxOption} from '@prescriberpoint/ui';

export const LOADER_STEP_TEXTS = [
  'Loading all insurance carriers',
  'Comparing drug status',
  'Uploading formulary information',
];

export const COVERAGE_FEEDBACK_FORM_URL = 'https://forms.gle/7u94oawVJNpc2wjM9';

export const REAUTHORIZATION_MMIT_ID = 3145;
export const QUANTITY_LIMIT_MMIT_ID = 23293;

// redis cache expiration times in seconds
export const COVERAGE_SEARCH_CACHE_EXPIRATION = 60 * 60 * 8; // 8 hours
export const COVERAGE_CHANNELS_CACHE_EXPIRATION = 60 * 60 * 24 * 30; // 30 days
export const COVERAGE_PLANS_CACHE_EXPIRATION = 60 * 60 * 24 * 30; // 30 days
export const COVERAGE_PA_REQUIREMENTS_CACHE_EXPIRATION = 60 * 60 * 24; // 1 day
export const COVERAGE_ST_REQUIREMENTS_CACHE_EXPIRATION = 60 * 60 * 24; // 1 day
export const COVERAGE_REAUTHORIZATION_CACHE_EXPIRATION = 60 * 60 * 24; // 1 day
export const COVERAGE_QL_CACHE_EXPIRATION = 60 * 60 * 24; // 1 day

// redis cache key values
export const COVERAGE_SEARCH_CACHE_KEY = 'mmitCoverageSearch';
export const COVERAGE_CHANNELS_CACHE_KEY = 'mmitChannelTypes';
export const COVERAGE_PLANS_CACHE_KEY = 'mmitPlanTypes';
export const COVERAGE_MCO_PLANS_CACHE_KEY = 'mmitMcoPlans';
export const COVERAGE_PA_REQUIREMENTS_CACHE_KEY = 'mmitPaRequirements';
export const COVERAGE_ST_REQUIREMENTS_CACHE_KEY = 'mmitStRequirements';
export const COVERAGE_REAUTHORIZATION_CACHE_KEY =
  'mmitReauthorizationRequirements';
export const COVERAGE_QL_CACHE_KEY = 'mmitQLRequirements';
export const COVERAGE_PRODUCT_CACHE_KEY = 'mmitProduct';
export const COVERAGE_NOTES_CACHE_KEY = 'mmitCoverageNotes';
export const COVERAGE_FORMULARY_DETAIL_CACHE_KEY = 'mmitFormularyDetail';

export const MCO_QUICK_PICKS_OPTIONS: IComboBoxOption[] = [
  {id: 597, value: 'UnitedHealthcare (UHC)'},
  {id: 16, value: 'Aetna Health'},
  {id: 168, value: 'CIGNA'},
  {id: 38, value: 'Anthem Blue Cross and Blue Shield'},
  {id: 196, value: 'TRICARE'},
  {id: 269, value: 'Humana'},
  {id: 632, value: 'Wellcare Health Plans'},
  {id: 91, value: 'FEPBlue'},
  {id: 198, value: 'VHA'},
  {id: 574, value: 'UHC Community Plan'},
];
