import {Text} from '@prescriberpoint/ui';
import {FC} from 'react';
import Icon from '@/components/Icon';
import {CEVENT_BUTTON_CLICKED} from '@/constants';
import {customEvent} from '@/utils/gtm';

const MedAIMobileCta: FC = () => {
  const onHandleClick = () => {
    customEvent(CEVENT_BUTTON_CLICKED, {id: 'med_ai_wc_button'});
  };

  return (
    <div className='static animate-fadeIn'>
      <div className='fixed bottom-0 left-0 z-20 w-full'>
        <button
          id='med_ai_wc_button'
          onClick={onHandleClick}
          className='flex h-12 w-full cursor-pointer items-center gap-x-2 rounded-t-[20px] bg-neutral-primary px-5 py-4 text-left text-neutral-lighter'>
          <Icon name='bot-message-square' size={20} />
          <Text
            as='body-sm'
            weight='bold'
            className='flex-1 !text-neutral-lighter'>
            Ask me anything
          </Text>
          <Icon name='chevron-up' size={24} className='text-neutral-light' />
        </button>
      </div>
    </div>
  );
};

export default MedAIMobileCta;
