// 3rd party css should be imported first so our styles will take precedence over them
import {GoogleTagManager, GoogleAnalytics} from '@next/third-parties/google';
import '@prescriberpoint/ui/index.css';
import {
  QueryClientProvider,
  QueryClient,
  HydrationBoundary,
} from '@tanstack/react-query';
import {ReactQueryDevtools} from '@tanstack/react-query-devtools';
import {NextPage} from 'next';
import type {AppContext, AppProps} from 'next/app';
// eslint-disable-next-line import/order
import App from 'next/app';
import Head from 'next/head';
import {useRouter} from 'next/router';
import Script from 'next/script';
import {ReactNode, useState, useEffect, ReactElement} from 'react';
import {CookiesProvider, useCookies} from 'react-cookie';
import Cookies from 'universal-cookie';
import {ErrorBoundary} from '@/components';
import HoneypotInput from '@/components/HoneypotInput';
import ToastContainerHolder from '@/components/ToastContainer';
import {
  COOKIE_CONSENT_COOKIE,
  NPI_COOKIE,
  SPLIT_USER_COOKIE,
} from '@/constants/cookies';
import {ADOBE_CDP_SCRIPT} from '@/constants/flags';
import {
  ADOBE_TAG_URL,
  GA_ID,
  GTM_ID,
  HOTJAR_ID,
  HOTJAR_SV,
  IS_PRODUCTION,
} from '@/constants/global';
import {
  FlagsProvider,
  DrugInteractionsProvider,
  ToastContextProvider,
  FlagsType,
} from '@/context';
import {AuthProvider} from '@/context/AuthProvider';
import {LoadingProvider} from '@/context/LoadingContext';
import {UserAgentProvider} from '@/context/UserAgentContext';
import {useRemoveAllCookies, useRenderInIFrame} from '@/hooks';
import {useClarity} from '@/hooks/useClarity';
import useClientJSFingerprint from '@/hooks/useClientJSFingerprint';
import {MainLayout} from '@/layouts';
import '@/public/styles.css';
import '../styles/globals.css';
import '../styles/fonts.css';
import 'react-toastify/dist/ReactToastify.min.css';
import '../styles/toast.css';
import featureFlags from '@/services/featureFlags';
import {
  hasDoNotTrackEnabled,
  hasGlobalPrivacyControlEnabled,
} from '@/utils/cookies';
import {pageView} from '@/utils/gtm';
import {parse} from '@/utils/userAgent';

let newrelic: any;
if (typeof window === 'undefined') {
  // This code will only run on the server side
  newrelic = require('newrelic');
}

export type NextPageWithLayout<P = {}> = NextPage<P> & {
  getLayout?: (page: ReactElement) => ReactNode;
};

type AppPropsWithLayout = AppProps & {
  Component: NextPageWithLayout;
  flags: FlagsType;
  uaString: string;
  browserTimingHeader: any;
  cookies: Cookies;
};

const MyApp = ({
  Component,
  pageProps,
  flags,
  uaString,
  browserTimingHeader,
  cookies: universalCookies,
}: AppPropsWithLayout) => {
  const [cookies] = useCookies([
    SPLIT_USER_COOKIE,
    COOKIE_CONSENT_COOKIE,
    NPI_COOKIE,
  ]);
  const {removeAllCookies} = useRemoveAllCookies();
  const pdpIsEmbedded = useRenderInIFrame();
  const hasBrowserDntOrGpcEnabled =
    hasDoNotTrackEnabled() || hasGlobalPrivacyControlEnabled();
  const hasRevokedConsent = cookies?.[COOKIE_CONSENT_COOKIE] === 'false';

  const loadAdobeCdp = flags?.[ADOBE_CDP_SCRIPT] === 'on';
  const clarityScriptEnabled = IS_PRODUCTION && !pdpIsEmbedded;
  const hotjarScriptEnabled = !pdpIsEmbedded;

  useClientJSFingerprint();
  const [queryClient] = useState(
    () =>
      new QueryClient({
        defaultOptions: {
          queries: {
            staleTime: 8 * 60 * 60 * 1000, // 8 hours
            refetchOnWindowFocus: false,
          },
        },
      }),
  );

  const getLayout =
    Component.getLayout ||
    ((page: ReactNode) => <MainLayout>{page}</MainLayout>);

  const router = useRouter();

  // Fire pageview with current url when page first loads
  useEffect(() => {
    pageView(window.location.href);
  }, []);

  useEffect(() => {
    router.events.on('routeChangeComplete', pageView);
    return () => {
      router.events.off('routeChangeComplete', pageView);
    };
  }, [router.events]);

  const {dehydratedState = null} = pageProps;

  useClarity();

  useEffect(() => {
    if (hasRevokedConsent) {
      removeAllCookies();
    } else if (IS_PRODUCTION) {
      //@ts-ignore
      window.clarity?.('consent');
    }
  }, [hasRevokedConsent, removeAllCookies]);

  let ua;
  if (uaString) {
    ua = parse(uaString);
  } else if (typeof window !== 'undefined') {
    ua = parse(window.navigator.userAgent);
  }

  // to hide nextjs error overlay (for cypress testing)
  const noOverlayWorkaroundScript = `
  window.addEventListener('error', event => {
    event.stopImmediatePropagation()
  })

  window.addEventListener('unhandledrejection', event => {
    event.stopImmediatePropagation()
  })
`;

  if (Component.name === 'PerformanceTestPage') {
    return <Component {...pageProps} />;
  }

  return (
    <FlagsProvider flags={flags}>
      <QueryClientProvider client={queryClient}>
        <HydrationBoundary state={dehydratedState}>
          <CookiesProvider cookies={new Cookies(universalCookies)}>
            <Head>
              <title>PrescriberPoint</title>
              <meta charSet='utf-8' />
              <meta
                name='description'
                content='Unlocking value in the Healthcare Provider and Pharma relationship. Enabling you to interact with Pharma on your own terms.'
                key='description'
              />
              <meta
                name='viewport'
                content='width=device-width, initial-scale=1, user-scalable=no, shrink-to-fit=no'
              />
            </Head>

            {process.env.NEXT_PUBLIC_DISABLE_ERROR_OVERLAY && (
              <script
                async
                dangerouslySetInnerHTML={{
                  __html: noOverlayWorkaroundScript,
                }}
              />
            )}

            <UserAgentProvider userAgent={ua}>
              <ErrorBoundary>
                <AuthProvider>
                  <DrugInteractionsProvider>
                    <ToastContextProvider>
                      <LoadingProvider>
                        {getLayout(
                          <>
                            <Component
                              {...{
                                ...pageProps,
                                ...dehydratedState,
                              }}
                            />
                            <ToastContainerHolder />

                            {hasRevokedConsent || hasBrowserDntOrGpcEnabled ? (
                              <>
                                <GoogleAnalytics gaId={GA_ID} />
                                <Script id='denied-consent'>
                                  {`
                                    gtag('consent', 'update', {
                                      'ad_storage': 'denied',
                                      'ad_user_data': 'denied',
                                      'ad_personalization': 'denied',
                                      'analytics_storage': 'denied'
                                  });
                                `}
                                </Script>
                              </>
                            ) : (
                              <>
                                <GoogleTagManager gtmId={GTM_ID} />
                                <Script
                                  id='browserTimingHeader'
                                  type='text/javascript'
                                  // @ts-ignore
                                  dangerouslySetInnerHTML={{
                                    __html: browserTimingHeader,
                                  }}
                                  strategy='afterInteractive'
                                />
                                {loadAdobeCdp ? (
                                  <Script
                                    id='adobe_tag'
                                    src={ADOBE_TAG_URL}
                                    defer
                                  />
                                ) : null}
                                {clarityScriptEnabled ? (
                                  <Script
                                    id='ms_clarity'
                                    strategy='afterInteractive'>
                                    {`
                                  (function(c,l,a,r,i,t,y){
                                    c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
                                    t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;
                                    y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
                                })(window, document, "clarity", "script", "iu4p96bp7b");
                                  `}
                                  </Script>
                                ) : null}
                                {hotjarScriptEnabled ? (
                                  <Script
                                    id='hotjar'
                                    strategy='afterInteractive'>
                                    {`(function(h,o,t,j,a,r){
                                        h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
                                        h._hjSettings={hjid:${HOTJAR_ID},hjsv:${HOTJAR_SV}};
                                        a=o.getElementsByTagName('head')[0];
                                        r=o.createElement('script');r.async=1;
                                        r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
                                        a.appendChild(r);
                                    })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=')`}
                                  </Script>
                                ) : null}
                              </>
                            )}
                          </>,
                        )}
                        <HoneypotInput />
                      </LoadingProvider>
                    </ToastContextProvider>
                  </DrugInteractionsProvider>
                </AuthProvider>
              </ErrorBoundary>
            </UserAgentProvider>
            <ReactQueryDevtools initialIsOpen={false} />
          </CookiesProvider>
        </HydrationBoundary>
      </QueryClientProvider>
    </FlagsProvider>
  );
};

MyApp.getInitialProps = async (appContext: AppContext) => {
  const appProps = await App.getInitialProps(appContext);

  const {req} = appContext.ctx;
  //@ts-ignore
  const cookies = req?.cookies || {};

  const flags = await featureFlags.fetchFlags();
  const browserTimingHeader = newrelic.getBrowserTimingHeader({
    hasToRemoveScriptWrapper: true,
  });

  const uaString = appContext?.ctx?.req?.headers['user-agent'];
  return {
    ...appProps,
    flags,
    uaString,
    browserTimingHeader,
    cookies,
  };
};

export default MyApp;
