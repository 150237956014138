import {customEvent, Pivot, PivotItem} from '@prescriberpoint/ui';
import clsx from 'clsx';
import {useRouter} from 'next/router';
import {FC, useRef, useState, useEffect, useCallback, useMemo} from 'react';
import {useCookies} from 'react-cookie';
import {CEVENT_NAVIGATE, DlvNavigate} from '@/constants';
import {SPLIT_USER_COOKIE} from '@/constants/cookies';
import * as FLAGS from '@/constants/flags';
import {
  OFFSET_SCROLL,
  OFFSET_SCROLL_WITH_PDP_HEADER_V2,
} from '@/constants/pdpPage';
import {
  SECTION_IDS as FULL_IDS,
  FULL_SECTION_IDS,
  OTHER_SECTION_IDS,
  SUBPAGES_SECTION_IDS,
} from '@/constants/sectionIDs';
import {
  useFlag,
  usePharmacyContext,
  useUserAgentContext,
  useMobileContentContext,
} from '@/context';
import {useLoadingContext} from '@/context/LoadingContext';
import {
  useCurrentSlug,
  useRenderInIFrame,
  useSectionIds,
  useShowEnhancedSection,
  useShowFixedCTAMenu,
  useUpdateEffect,
  useViewportOffset,
} from '@/hooks';
import {useIsCoverageToolAvailable} from '@/hooks/useIsCoverageToolAvailable';
import useIsPDPSubpage from '@/hooks/useIsPDPSubpage';
import {useSectionsStatus} from '@/hooks/useSectionsStatus';
import {getSectionTitleFromId} from '@/modules/ProductDetailPage/utils';
import {useEnhancedContent, useLabel} from '@/queries';
import {toSnakeCase} from '@/utils/string';
import {isElementVisible} from '@/utils/ui';

interface PivotMenuProps {}

export const PIVOT_PREFIX_ID = 'ss_pivot_';

const PivotMenu: FC<PivotMenuProps> = () => {
  const {setId, slug, section} = useCurrentSlug();
  const {setShowCTAMenu} = useMobileContentContext();
  const isPDPSubpage = useIsPDPSubpage();
  const {data: product} = useLabel(setId);
  const {isFetching: isInitialLoading} = useEnhancedContent(setId, {
    enabled: !!setId,
  });

  const showCTAFixedMenu = useShowFixedCTAMenu();

  const {anchors, sectionIds: SECTION_IDS} = useSectionIds();

  const router = useRouter();
  const [activeItem, setActiveItem] = useState('');
  const [scrolledToBottom, setScrolledToBottom] = useState(false);
  const pivotRef = useRef<HTMLDivElement>(null);
  const pivotItemsPositions = useRef(null);
  const {isLoading} = useLoadingContext();
  const muteScrolling = useRef(false);
  const viewportOffset = useViewportOffset();
  const {isMobileOrTablet} = useUserAgentContext();
  const {showPH} = usePharmacyContext();
  const pdpIsEmbedded = useRenderInIFrame();
  const showPA = useShowEnhancedSection(
    FULL_IDS.priorAuthorization,
    FLAGS.PRIOR_AUTHORIZATION_SECTION,
  );
  const showRS = useShowEnhancedSection(
    FULL_IDS.requestSamples,
    FLAGS.REQUEST_SAMPLES_SECTION,
    true,
  );
  const showPubMed = useShowEnhancedSection(
    FULL_IDS.pubMed,
    FLAGS.PUB_MED_ARTICLES,
    false,
  );
  const showFAQS =
    useShowEnhancedSection(
      FULL_IDS.peopleAlsoAsk,
      FLAGS.PEOPLE_ALSO_ASK_SECTION,
      false,
    ) && !pdpIsEmbedded;

  const {showCoverageOnPdp} = useIsCoverageToolAvailable();
  const showNewDesign = useFlag(FLAGS.SHOW_V2_PPT_DESIGN);

  const [cookies] = useCookies(['hubspotutk', SPLIT_USER_COOKIE]);
  const refFireCEvent = useRef(false);

  const getSectionTitle = useCallback(
    (id: string) => {
      if (
        id === SECTION_IDS.summary ||
        id === OTHER_SECTION_IDS.dosingAndAdmiministration
      ) {
        return 'Dosage & Administration';
      }

      if (id === SECTION_IDS.pubMed) {
        return 'PubMed™ News';
      }

      if (id === SECTION_IDS.coverage) {
        return 'Coverage';
      }

      return getSectionTitleFromId(id);
    },
    [SECTION_IDS],
  );

  useEffect(() => {
    // Get the anchor value from the URL
    const anchor = router?.asPath?.split('#')[1];

    if (anchor && product && !isInitialLoading) {
      muteScrolling.current = true;
      setTimeout(() => {
        document.getElementById(`ss_pivot_${anchor}`)?.click();
        setTimeout(() => {
          muteScrolling.current = false;
        }, 1001); //As the animation takes 1000ms
      }, 1000);
    }
  }, [isInitialLoading, product, router?.asPath]);

  useEffect(() => {
    if (isPDPSubpage) {
      const currentSection = SUBPAGES_SECTION_IDS[section];
      setActiveItem(currentSection);
    }
  }, [isPDPSubpage, section]);

  const sectionsStatus = useSectionsStatus();
  const showFA = sectionsStatus[FULL_SECTION_IDS.financialAssistance];

  const filteredAnchors = useMemo(
    () =>
      anchors.filter((item) => {
        const dosage =
          getSectionTitle(item) === 'Dosage & Administration' && pdpIsEmbedded;
        const label = getSectionTitle(item) === 'Drug label' && pdpIsEmbedded;
        const samples =
          getSectionTitle(item) === 'Samples' && (pdpIsEmbedded || !showRS);
        const pharmacy =
          getSectionTitle(item) === 'Pharmacy' && (pdpIsEmbedded || !showPH);
        const priorAuthorization =
          getSectionTitle(item) === 'Prior authorization' && !showPA;
        const financialAssistance =
          getSectionTitle(item) === 'Financial assistance' && !showFA;
        const faqs = getSectionTitle(item) === 'People also ask' && !showFAQS;
        const pubMed = getSectionTitle(item) === 'PubMed™ News' && !showPubMed;
        getSectionTitle(item);
        const coverage =
          getSectionTitle(item) === 'Coverage' && !showCoverageOnPdp;

        if (
          dosage ||
          label ||
          pharmacy ||
          samples ||
          priorAuthorization ||
          financialAssistance ||
          faqs ||
          pubMed ||
          coverage
        ) {
          return false;
        }
        return item;
      }),
    [
      anchors,
      getSectionTitle,
      showPH,
      showPA,
      showRS,
      showFAQS,
      showPubMed,
      pdpIsEmbedded,
      showCoverageOnPdp,
      showFA,
    ],
  );

  useEffect(() => {
    const pdpWrapper = document.scrollingElement!;
    if (isLoading) {
      pdpWrapper.scrollTo({
        top: 0,
      });
      setActiveItem(filteredAnchors[-1]);
    }
  }, [isLoading, filteredAnchors]);

  useUpdateEffect(() => {
    if (activeItem && !isMobileOrTablet && !isPDPSubpage) {
      window.history.replaceState(window.history.state, '', `#${activeItem}`);
    }
  }, [activeItem]);

  const checkIfPdpHeaderV1IsVisible = useCallback(() => {
    if (showNewDesign) {
      const pdpHeaderV1 = document.querySelector('#pdp-header-v1');
      const isPdpHeaderV1Visible = pdpHeaderV1
        ? isElementVisible(
            pdpHeaderV1.getBoundingClientRect(),
            viewportOffset.current,
          )
        : false;

      setShowCTAMenu(isPdpHeaderV1Visible);
    }
  }, [setShowCTAMenu, viewportOffset, showNewDesign]);

  const hasScrolledToTop = useCallback(() => {
    const firstAnchor = filteredAnchors[0];
    const el = document.querySelector(`#${firstAnchor}`);

    if (!el) {
      return false;
    }

    return (
      el.getBoundingClientRect().top >
      (showCTAFixedMenu ? OFFSET_SCROLL_WITH_PDP_HEADER_V2 : OFFSET_SCROLL)
    );
  }, [filteredAnchors, showCTAFixedMenu]);

  const hasScrolledToBottom = () => {
    const pdpWrapper = document.scrollingElement!;

    const hasScrolledToBottomVal =
      Math.floor(pdpWrapper.scrollHeight - pdpWrapper.scrollTop) ===
      pdpWrapper.clientHeight;

    setScrolledToBottom(hasScrolledToBottomVal);
    return hasScrolledToBottomVal;
  };

  const isIntercomOpened = () => {
    const hasIntercomOpened = document
      .getElementsByTagName('html')[0]
      .classList.contains('intercom-mobile-messenger-active');

    return hasIntercomOpened;
  };

  const scrollHandler = useCallback(() => {
    if (showCTAFixedMenu) {
      checkIfPdpHeaderV1IsVisible();
    }

    if (isIntercomOpened()) {
      setActiveItem('');
      const pivotEl = document.querySelector('#pivotMenu')!;

      if (pivotEl) {
        pivotEl.scrollTo({
          left: 0,
          behavior: 'smooth',
        });
      }
      return;
    }

    if (muteScrolling.current || isPDPSubpage) {
      return;
    }

    if (hasScrolledToBottom()) {
      setActiveItem(filteredAnchors[filteredAnchors.length - 1]);
    } else if (hasScrolledToTop()) {
      setActiveItem('');
    } else {
      const visibleItem = filteredAnchors.find((anchor) => {
        const el = document.querySelector(`#${anchor}`);

        if (!el) {
          return false;
        }

        return isElementVisible(
          el.getBoundingClientRect(),
          viewportOffset.current,
          showCTAFixedMenu ? 30 : undefined,
        );
      });

      if (visibleItem) {
        setActiveItem(visibleItem);
      }
    }
  }, [
    filteredAnchors,
    isPDPSubpage,
    viewportOffset,
    checkIfPdpHeaderV1IsVisible,
    hasScrolledToTop,
    showCTAFixedMenu,
  ]);

  const handleItemClick = useCallback(
    (anchor: string, isTrusted: boolean) => {
      if (showCTAFixedMenu) {
        checkIfPdpHeaderV1IsVisible();
      }

      if (isMobileOrTablet) {
        window.history.replaceState(window.history.state, '', `#${anchor}`);
      }
      muteScrolling.current = true;
      setActiveItem(anchor);
      if (refFireCEvent.current && isTrusted) {
        customEvent<DlvNavigate>(CEVENT_NAVIGATE, {
          pageSection: getSectionTitleFromId(anchor),
          userKey: cookies[SPLIT_USER_COOKIE],
        });
      }
      setTimeout(() => {
        refFireCEvent.current = true;
        muteScrolling.current = false;
      }, 1001); //As the animation takes 1000ms

      if (isPDPSubpage && anchor !== SUBPAGES_SECTION_IDS[section]) {
        router.push(`/therapies/${slug}#${anchor}`);
        return;
      }
    },
    [
      showCTAFixedMenu,
      isMobileOrTablet,
      isPDPSubpage,
      router,
      section,
      slug,
      cookies,
      checkIfPdpHeaderV1IsVisible,
    ],
  );

  //get pivot items positions for pivot horizontal scrolling
  useEffect(() => {
    if (!pivotItemsPositions.current) {
      let initialPositions: any = {};
      filteredAnchors.forEach((section) => {
        const pivotItemElement = document.querySelector(
          `#${PIVOT_PREFIX_ID}${section}`,
        );
        if (pivotItemElement) {
          initialPositions[section] =
            pivotItemElement?.getBoundingClientRect().left;
        }
      });
      pivotItemsPositions.current = initialPositions;
    }
  }, [filteredAnchors]);

  useEffect(() => {
    window.addEventListener('scroll', scrollHandler, true);
    return () => window.removeEventListener('scroll', scrollHandler);
  }, [scrollHandler]);

  //horizontal scroll into view selected pivot item
  useEffect(() => {
    const anchorElem = document?.querySelector(
      `#${PIVOT_PREFIX_ID}${activeItem}`,
    );

    if (anchorElem && pivotItemsPositions?.current) {
      const pivotEl = document.querySelector('#pivotMenu')!;
      const initialLeftPosition =
        pivotItemsPositions?.current[filteredAnchors[0]]!;
      pivotEl.scrollTo({
        left: pivotItemsPositions?.current[activeItem] - initialLeftPosition,
        behavior: 'smooth',
      });
    }
  }, [activeItem, filteredAnchors]);

  return (
    <div
      className={clsx(
        'relative z-20 h-11 w-full whitespace-nowrap bg-inherit',
        showNewDesign ? 'bg-neutral-lighter-alt' : 'bg-white',
        {
          'after:z-1 after:pointer-events-none after:absolute after:right-0 after:top-0 after:h-full after:w-1/6 after:bg-gradient-to-l after:from-white after:content-[""]':
            !scrolledToBottom,
        },
      )}
      ref={pivotRef}>
      <div
        id='pivotMenu'
        data-testid='pivotMenu'
        className={clsx(
          'stickyHeader -after:bottom-0 h-full w-full max-w-[1440px] overflow-y-hidden after:absolute after:h-0.5 after:w-full after:max-w-[1440px] after:bg-neutral-quaternary after:content-[""] md:mx-auto',
        )}>
        <Pivot>
          {filteredAnchors.map((item) => (
            <PivotItem
              key={item}
              id={`${PIVOT_PREFIX_ID}${toSnakeCase(item)}`}
              label={getSectionTitle(item)}
              offset={
                showCTAFixedMenu
                  ? -OFFSET_SCROLL_WITH_PDP_HEADER_V2
                  : -OFFSET_SCROLL
              }
              anchor={item}
              selected={activeItem === item}
              // ignore ts since signature of onClick on react-scroll lib dont receive
              // params but it actually does receive the event object
              // @ts-ignore
              handleClick={(
                e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
              ) => handleItemClick(item, e.isTrusted)}
              hasDot={false}
            />
          ))}
        </Pivot>
      </div>
    </div>
  );
};

export default PivotMenu;
