import {useQuery} from '@tanstack/react-query';
import {Cookies} from 'react-cookie';
import {ACCESS_TOKEN_COOKIE} from '@/constants/cookies';
import {read} from '@/services/api';

export interface ILocation {
  locationId: string;
  practiceId: string;
  locationName: string;
  practiceName: string;
}

const cookies = new Cookies();

export async function getLocation(token: string): Promise<ILocation | null> {
  let data = null;

  try {
    data = await read<ILocation>(
      'HCPLocation',
      {
        parentName: 'Location',
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
      'profile',
    );
  } catch (e) {
    console.error(e);
  }
  return data;
}

export function useLocation(options = {}) {
  const accessToken = cookies.get(ACCESS_TOKEN_COOKIE);

  return useQuery<ILocation | null>({
    queryKey: ['location'],
    queryFn: async () => await getLocation(accessToken),
    ...options,
  });
}
