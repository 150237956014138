import {Icon, StatusTag, Text, Tooltip} from '@prescriberpoint/ui';
import {FC} from 'react';

export interface IAffordabilityResourceProps {
  resourceUrl: string;
  resourceName?: string;
  onClick?: () => void;
}

const AffordabilityResource: FC<IAffordabilityResourceProps> = ({
  resourceUrl,
  resourceName,
  onClick,
}) => (
  <button
    onClick={onClick}
    className='flex w-full items-center justify-between gap-x-4 rounded-lg bg-white px-3 py-4 text-left shadow-affordability'>
    <div className='flex gap-x-2'>
      <Tooltip
        content={resourceUrl}
        className='left-[200px]'
        containerClassName='min-w-0 flex-1 '>
        <Text as='body-sm' weight='semibold' className='max-w-45 truncate'>
          {resourceName ?? resourceUrl}
        </Text>
      </Tooltip>
      <StatusTag size='sm' type='pending' label='Eng' />
    </div>
    <Icon name='OpenLink' className='size-4 text-neutral-darker' />
  </button>
);

export default AffordabilityResource;
