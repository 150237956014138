import {FC} from 'react';
import BoxedWarning from './BoxedWarning';
import Dosages from './Dosages';
import Otc from './OTC';
import RemsInfo from './RemsInfo';
import HeroIcons from '@/components/HeroIcons';
import {HEADER_REMS_INFO} from '@/constants/flags';
import {useUserAgentContext, useFlag} from '@/context';
import {useCurrentDrug, useIsOtc} from '@/hooks';
import {useRemsBySetId} from '@/queries';

const ChipSection: FC = () => {
  const {setId, drugName} = useCurrentDrug();
  const {isMobileOrTablet} = useUserAgentContext();
  const isOtc = useIsOtc();

  const remsInfoOn = useFlag(HEADER_REMS_INFO);
  const {data, isFetching} = useRemsBySetId(setId);
  const showRemsInfo = !!(remsInfoOn && data && !isFetching);

  return (
    <>
      {isOtc ? <Otc /> : null}
      <Dosages />
      {showRemsInfo && !isMobileOrTablet ? <RemsInfo /> : null}
      <BoxedWarning />
      {!isMobileOrTablet ? <HeroIcons drugName={drugName} /> : null}
    </>
  );
};

export default ChipSection;
