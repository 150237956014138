import {Text} from '@prescriberpoint/ui';
import {FC, useMemo} from 'react';
import {AFFORDABILITY_NOT_AVAILABLE_STRING_VALUE} from '@/models';
import {isNumber} from '@/utils/validations';

export interface IProgramCostsProps {
  copayCost?: number | null;
  annualCapCost?: string | null;
}

const ProgramCosts: FC<IProgramCostsProps> = ({copayCost, annualCapCost}) => {
  const copay = useMemo(
    () => (copayCost ? `$${copayCost}` : null),
    [copayCost],
  );
  const annualCap = useMemo(() => {
    const annualCapCostWithSymbol =
      annualCapCost && isNumber(annualCapCost) ? `$${annualCapCost}` : null;
    if (
      annualCapCost &&
      annualCapCost !== AFFORDABILITY_NOT_AVAILABLE_STRING_VALUE
    ) {
      return annualCapCost.startsWith('$')
        ? annualCapCost
        : annualCapCostWithSymbol;
    }
    return null;
  }, [annualCapCost]);

  if (!copay && !annualCap) {
    return null;
  }

  return (
    <div className='w-full text-left'>
      {copay ? (
        <Text data-testid='copay-cost' as='body-md'>
          {copay} Copay{annualCap ? ' - ' : null}
        </Text>
      ) : null}
      {annualCap ? (
        <Text data-testid='annual-cap-cost' as='body-md'>
          {annualCap} Annual Cap
        </Text>
      ) : null}
    </div>
  );
};

export default ProgramCosts;
