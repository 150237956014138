import {StatusTag, Text} from '@prescriberpoint/ui';
import clsx, {ClassValue} from 'clsx';
import {FC, useId, useMemo} from 'react';
import Icon from '../Icon';
import ProgramCosts from './ProgramCosts';

export const LILLY_CARES_TEXT = 'lillycares';

export interface IAffordabilityProgramCardProps {
  id?: string;
  title: string;
  assistanceType: string;
  copayCost?: number | null;
  annualCapCost?: string | null;
  programUrl: string;
  onClick?: () => void;
  className?: ClassValue;
}

const AffordabilityProgramCard: FC<IAffordabilityProgramCardProps> = ({
  id,
  title,
  assistanceType,
  copayCost,
  annualCapCost,
  programUrl,
  onClick,
  className,
}) => {
  const generatedId = useId();

  const programTypeText = useMemo(
    () => (assistanceType === 'PAP' ? 'Patient Assistance' : assistanceType),
    [assistanceType],
  );

  return (
    <button
      id={id ?? generatedId}
      data-testid={id ?? 'affordability-program-card-btn'}
      onClick={onClick}
      className={clsx(
        'flex rounded-xl border border-solid border-neutral-light bg-white p-4 hover:border-transparent hover:shadow-affordability-card',
        className,
      )}>
      <div className='flex h-full flex-1 flex-col justify-between gap-y-4'>
        <div className='flex flex-col gap-y-2 text-left'>
          <Text as='title-md' weight='bold'>
            {title}
          </Text>
          {programUrl.includes(LILLY_CARES_TEXT) ? (
            <Text as='body-md'>Free for eligible patients</Text>
          ) : null}
          <ProgramCosts copayCost={copayCost} annualCapCost={annualCapCost} />
        </div>
        <StatusTag
          className='whitespace-nowrap'
          label={programTypeText}
          type='outline'
          size='lg'
        />
      </div>
      <div className='self-end'>
        <Icon name='external-link' size={16} className='text-neutral-darker' />
      </div>
    </button>
  );
};

export default AffordabilityProgramCard;
