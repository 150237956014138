import {useRouter} from 'next/compat/router';
import {FC, useState, createContext, useContext} from 'react';
import {useCookies} from 'react-cookie';
import ReactGA from 'react-ga';
import {CEVENT_SEARCH, DlvSearch} from '@/constants';
import {SPLIT_USER_COOKIE} from '@/constants/cookies';
import {ACTIONS} from '@/data/searchbarActionsOptions';
import {DrugSearchResult, DrugsSavedByUserResult} from '@/interfaces';
import {customEvent} from '@/utils/gtm';

interface SearchProps {
  gaID: string;
  reason: string | null;
  onHandleClose?: () => void;
  showLabelTags?: boolean;
}

interface ISearchContext {
  query: string;
  error: boolean;
  hasResults: boolean;
  selectedValue: DrugSearchResult | DrugsSavedByUserResult | undefined;
  setQuery: (query: string) => void;
  setSelectedValue: (
    searchedItem: DrugSearchResult | DrugsSavedByUserResult | undefined,
  ) => void;
  handleSearch: (drug?: DrugsSavedByUserResult) => void;
  setDefaultSlug: (slug: string) => void;
  setHasResults: (hasResults: boolean) => void;
  onHandleClose: () => void;
  showLabelTags: boolean;
}

const defaultState = {
  query: '',
  setQuery: () => {},
  selectedValue: undefined,
  setSelectedValue: () => {},
  handleSearch: () => {},
  error: false,
  selectedAction: ACTIONS[0],
  setSelectedAction: () => {},
  setDefaultSlug: () => {},
  hasResults: false,
  setHasResults: () => {},
  onHandleClose: () => {},
  showLabelTags: false,
};

export const SearchContext = createContext<ISearchContext>(defaultState);

export const useSearchContext = () => useContext(SearchContext);

export const SearchProvider: FC<SearchProps> = ({
  reason,
  showLabelTags = false,
  onHandleClose,
  ...props
}) => {
  const defaultReason = ACTIONS.find((action) => action.text === reason);

  const [hasResults, setHasResults] = useState(false);

  const [query, setQuery] = useState('');
  const [selectedValue, setSelectedValue] = useState<
    DrugSearchResult | DrugsSavedByUserResult | undefined
  >();
  const [showError, setShowError] = useState(false);
  const [cookies] = useCookies([SPLIT_USER_COOKIE]);

  const [defaultSlug, setDefaultSlug] = useState<unknown>('');
  const router = useRouter();

  const handleSearch = (drug?: DrugsSavedByUserResult) => {
    const selected_slug = drug?.slug || selectedValue?.slug;
    const selected_name = drug?.label_title || selectedValue?.label_title;
    const selected_pharmaCompany = drug?.labeler;
    const final_slug = selected_slug || defaultSlug;

    if (selected_name) {
      const path = `/therapies/${final_slug}${
        defaultReason?.key ? `?help=${defaultReason.key}` : ''
      }`;

      customEvent<DlvSearch>(CEVENT_SEARCH, {
        search: selected_name,
        pharmaCompany: selected_pharmaCompany ?? '',
        userKey: cookies[SPLIT_USER_COOKIE],
      });

      if (router) router.push(`${path}`);

      if (props.gaID) {
        //eslint-disable-next-line
        ReactGA.event({
          category: 'discovery',
          action: defaultReason?.text || '',
          label: selectedValue?.label_title,
        });
      }
      if (showError) setShowError(false);
    } else {
      setShowError(true);
    }
  };

  const values = {
    query: query,
    setQuery: setQuery,
    error: showError,
    selectedValue,
    setSelectedValue,
    handleSearch: handleSearch,
    setDefaultSlug,
    showLabelTags: showLabelTags,
    hasResults,
    setHasResults,
    onHandleClose: !!onHandleClose ? onHandleClose : () => {},
  };

  return <SearchContext.Provider value={values} {...props} />;
};
