import {IUserOption} from '@prescriberpoint/ui';
import {useQuery} from '@tanstack/react-query';
import {PracticeUserDto} from '@/models';
import {read} from '@/services/api';

export async function getPracticeUserOptions(
  email: string,
): Promise<IUserOption[]> {
  if (!email) return [];

  let data: PracticeUserDto[] = [];
  try {
    data = await read<PracticeUserDto[]>(
      'Person',
      {
        params: {
          domain: email,
        },
      },
      {},
      'profile',
      true,
    );
  } catch (e) {
    console.error(e);
  }

  const usersOptions: IUserOption[] =
    data
      ?.map((user) => ({
        fullName:
          user.firstName && user.lastName
            ? `${user.firstName} ${user.lastName}`
            : user.name,
        imgUrl: '',
        key: user.id,
        inviteAccepted: user.inviteAccepted,
        email: user.email,
      }))
      .filter((e) => e.fullName?.trim()) ?? [];

  usersOptions.sort(
    (a, b) => (b.inviteAccepted ? 1 : 0) - (a.inviteAccepted ? 1 : 0),
  );

  const key = 'key';
  return [...new Map(usersOptions.map((item) => [item[key], item])).values()]; // TODO: backend is returning duplicated users (with same id)
}

export function usePracticeUserOptions(email: string, options = {}) {
  return useQuery<IUserOption[]>({
    queryKey: ['userOptions', email],
    queryFn: async () => await getPracticeUserOptions(email),
    ...options,
  });
}
