import {
  CEVENT_SPLIT_EXPERIMENT,
  CEVENT_SPLIT_IMPRESSION,
  DlvSplitExperiment,
  DlvSplitImpression,
} from '@/constants';
import {IS_PRODUCTION} from '@/constants/global';

export const pageView = (url: string, enableClarity: boolean = true) => {
  //@ts-ignore
  window.dataLayer?.push({
    event: 'pageview',
    page: url,
  });

  if (IS_PRODUCTION && enableClarity) {
    //@ts-ignore
    window?.clarity?.('event', 'pageview');
  }
};

export const customEvent = <T>(
  name: string,
  data?: T,
  enableClarity: boolean = true,
) => {
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const params = Object.fromEntries(urlParams.entries());
  //@ts-ignore
  window.dataLayer?.push({
    event: name,
    event_data: {...data, embedded: params.origin_id || 'none'},
  } as T);

  if (IS_PRODUCTION && enableClarity) {
    //@ts-ignore
    window?.clarity?.('event', name);
  }
};

export const splitCustomEvent = (data: DlvSplitExperiment) => {
  customEvent(CEVENT_SPLIT_EXPERIMENT, {
    experiment: data,
  });
};

export const splitImpression = (data: DlvSplitImpression) =>
  customEvent(CEVENT_SPLIT_IMPRESSION, {experiment: data});

// Define the return type of getLastVariable
type VariableObject = {
  [key: string]: any; // Index signature for dynamic keys
};

export const getLastVariable = (name: string): VariableObject | undefined =>
  //@ts-ignore
  window.dataLayer?.filter((item) => item[name]).slice(-1)[0];

export const setVariable = (name: string, value: any) => {
  //@ts-ignore
  window.dataLayer?.push({
    [name]: value,
  });
};

export const appendToArrayVariable = (variableName: string, data: any) => {
  const variableObject = getLastVariable(variableName);
  if (variableObject) {
    const items = variableObject[variableName];
    if (items) {
      setVariable(variableName, [data, ...items]);
    } else {
      setVariable(variableName, [data]);
    }
  }
};

export const proccessSectionId = (sectionId: string) =>
  sectionId
    .replace(/[_-]/g, ' ')
    .replace(/parent\s*section|section/gi, '')
    .split(/(?=[A-Z])/)
    .map((s) => s.toLowerCase())
    .join(' ');

export const getEmailDomain = (email: string) => email?.split('@')[1];
