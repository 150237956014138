import {useMemo, useState} from 'react';
import {useCookies} from 'react-cookie';
import useUserData from './useUserData';
import {ACCESS_TOKEN_COOKIE} from '@/constants/cookies';
import parseJwt from '@/utils/jwt';

export interface IUser {
  loading: boolean;
  nameInitials: string;
  logged: boolean;
  email: any;
  sub: any;
  firstName: string;
  fullName: string;
  profilePicture: string;
  termsAndConditions: boolean;
  role?: string;
}

export const defaultUser: IUser = {
  loading: false,
  nameInitials: '',
  logged: false,
  email: null,
  sub: null,
  firstName: '',
  fullName: '',
  profilePicture: '',
  termsAndConditions: false,
  role: '',
};

const useUser = (): IUser => {
  const [loading, setIsLoading] = useState(true);

  const {userData} = useUserData();
  const [cookies] = useCookies([ACCESS_TOKEN_COOKIE]);

  const logged = useMemo(() => {
    setIsLoading(false);
    return !!cookies.accessToken;
  }, [cookies.accessToken]);

  const decodedToken = parseJwt(cookies[ACCESS_TOKEN_COOKIE]);

  const emailEntry =
    decodedToken &&
    Object.entries(decodedToken).find(([k]) => k.includes('email'));

  const email = emailEntry ? emailEntry[1] : null;

  let nameInitials = '';

  if (logged) {
    nameInitials = `${
      (userData?.givenName && userData?.givenName[0]) || email[0] || ''
    }${(userData?.familyName && userData?.familyName[0]) || email[1] || ''}`;
  }

  const firstName = userData?.givenName?.trim() || '';
  const lastName = userData?.familyName?.trim() || '';

  return {
    loading: loading,
    nameInitials,
    logged,
    email: emailEntry ? emailEntry[1] : null,
    sub: decodedToken?.sub,
    firstName: firstName,
    fullName: (firstName ?? '') + ((lastName && ` ${lastName}`) ?? ''),
    profilePicture: userData.picture,
    termsAndConditions: userData.termsAndConditions,
  };
};

export default useUser;
