import {Text} from '@prescriberpoint/ui';
import {useRouter} from 'next/router';
import {FC} from 'react';
import Share from '@/components/Share';
import {PROTOCOL_HOSTNAME} from '@/constants/global';
import {useCurrentDrug} from '@/hooks';

const ShareStep: FC = () => {
  const {drugName} = useCurrentDrug();
  const router = useRouter();

  return (
    <>
      <Text as='title-lg' weight='extrabold'>
        Share
      </Text>
      <Share
        id='summary_share_info'
        isShareOpen={!!true}
        handleClose={() => {}}
        renderUnderButton
        shareLink={`${PROTOCOL_HOSTNAME}${router.asPath}`}
        resource={drugName}
        drugName={drugName}
        section='share'
        onlyContent
      />
    </>
  );
};

export default ShareStep;
