import {CONTENT_SUMMARIES} from '@/constants/flags';
import {useFlag} from '@/context';
import {ENABLED_DRUG_SUMMARIES} from '@/data/contentSummaries';

export function useShowContentSummaries(setId: string) {
  const showContentSummaries = useFlag(CONTENT_SUMMARIES);
  const drugIncluded = ENABLED_DRUG_SUMMARIES.includes(setId);

  return showContentSummaries && drugIncluded;
}
