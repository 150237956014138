import {useCallback, useMemo} from 'react';
import {useIsOncology} from './useIsOncology';
import * as FLAGS from '@/constants/flags';
import {
  SECTION_IDS,
  SECTION_IDS_ORDER,
  STANDARD_SECTION_IDS,
  ONCOLOGY_SECTION_IDS,
  FIXED_RESOURCE_SECTIONS,
} from '@/constants/sectionIDs';
import {FlagsType, useFlag, useFlags} from '@/context';
import {PARENT_SECTION_ID_MAP, useCurrentSlug, useEnhanced} from '@/hooks';
import {EnhancedContentLinkDto} from '@/models';

interface ISectionIds {
  summary?: string;
  drugLabel: string;
  requestSamples?: string;
  priorAuthorization?: string;
  pharmacy?: string;
  financialAssistance?: string;
  pubMed?: string;
  patientEducation?: string;
  efficacy?: string;
  coverage?: string;
  peopleAlsoAsk?: string;
}

function filterFlags(ids: ISectionIds, flags: FlagsType): Array<string> {
  return Object.values(ids).filter((id) => {
    // if there is a flag for this section, show it only if it is enabled
    if (flags[`ppt_${id}_section`]) return flags[`ppt_${id}_section`] == 'on';
    return true;
  });
}

function filterAnchorsForEnhancedContent(
  initialAnchors: string[],
  links: EnhancedContentLinkDto[],
) {
  return initialAnchors.filter((anchor) =>
    !FIXED_RESOURCE_SECTIONS.includes(anchor)
      ? true
      : links.some((link) =>
          link.tags.some((tag) => tag.id === PARENT_SECTION_ID_MAP[anchor]),
        ),
  );
}

export function useSectionIds(): {anchors: string[]; sectionIds: ISectionIds} {
  const {flags} = useFlags();
  const {setId} = useCurrentSlug();
  const {isEnhanced, enhancedContent} = useEnhanced(setId);
  const {isOncologyDrug} = useIsOncology();
  const showBM = useFlag(FLAGS.BIOMARKER_SECTION);
  const showCoverage = useFlag(FLAGS.COVERAGE_ON_PDP);
  const sectionsOrders = SECTION_IDS_ORDER;
  const sectionsIds = SECTION_IDS;
  const standardSectionIds = STANDARD_SECTION_IDS;

  const addOtherSections = useCallback(
    (sections: ISectionIds) => {
      if (isOncologyDrug && showBM) {
        return {...sections, ...ONCOLOGY_SECTION_IDS};
      } else {
        return sections;
      }
    },
    [isOncologyDrug, showBM],
  );

  const anchors = useMemo(() => {
    let anchors: string[] = [];

    if (isEnhanced) {
      anchors = filterFlags(addOtherSections(sectionsIds), flags);
      anchors = filterAnchorsForEnhancedContent(
        anchors,
        enhancedContent?.links || [],
      );
    } else {
      anchors = filterFlags(addOtherSections(standardSectionIds), flags);
    }

    const sortedAnchors = (() => {
      const sorted: string[] = [];
      anchors.forEach((anchor) => {
        sorted[sectionsOrders[anchor]] = anchor;
      });
      //TODO: remove once this prescribability is prod flag is on
      return sorted.filter((anchor) => {
        switch (anchor) {
          case SECTION_IDS.coverage:
            return showCoverage;
          default:
            return true;
        }
      });
    })();

    return sortedAnchors;
  }, [
    isEnhanced,
    sectionsIds,
    standardSectionIds,
    flags,
    addOtherSections,
    showCoverage,
    sectionsOrders,
    enhancedContent,
  ]);

  return {anchors, sectionIds: isEnhanced ? sectionsIds : standardSectionIds};
}
