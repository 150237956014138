export type DistributionType = 'limited' | 'exclusive' | 'unknown';

export interface PharmacyByBrandDto {
  phoneNumber?: string;
  address: string;
  logo: string;
  distribution: DistributionType;
  name: string;
  limitedAndExclusiveDrugs: number;
  healthPlans: number;
  slug: string;
}

export interface PharmacyDetailsDto {
  name: string;
  address: string;
  patient_services: string[];
  pharma_services: string[];
  contracted_plans: string[];
  objectID: string;
  description: string;
  phone: string;
  email: string;
  payer_services: string[];
  distribution: DistributionType;
  logo: string;
  limited_brands: PharmacyDtoExclusiveBrand[];
  exclusive_brands: PharmacyDtoExclusiveBrand[];
  websiteUrl?: string;
}

export interface PharmacyResultDto {
  name: string;
  address: PharmacyAddressDto;
  _geoloc?: PharmacyGeolocDto;
  slug: string;
  patient_services: string[];
  pharma_services: string[];
  conditions: string[];
  contracted_plans: string[];
  limited_brands: PharmacyDtoExclusiveBrand[];
  exclusive_brands: PharmacyDtoExclusiveBrand[];
  logo: string;
  objectID: string;
  accreditation: string[];
  _highlightResult?: PharmacyHighlightResult;
  description: string;
  phone: string;
  email: string;
  payer_services: string[];
}

export interface PharmacyGeolocDto {
  lat: number;
  lng: number;
}

export interface PharmacyHighlightResult {
  name: Logo;
  address: PharmacyAddressDto;
  _geoloc: HighlightResultGeoloc;
  slug: Logo;
  patient_services: Logo[];
  pharma_services: Logo[];
  conditions: Logo[];
  contracted_plans: Logo[];
  limited_brands: HighlightResultExclusiveBrand[];
  exclusive_brands: HighlightResultExclusiveBrand[];
  logo: Logo;
}

export interface HighlightResultGeoloc {
  lat: Logo;
  lng: Logo;
}

export interface Logo {
  value: string;
  matchLevel: MatchLevel;
  matchedWords: any[];
}

export enum MatchLevel {
  None = 'none',
}

export interface HighlightResultExclusiveBrand {
  name: Logo;
  href: Logo;
  set_id: Logo;
}

export interface PharmacyAddressDto {
  street: string;
  street2: string;
  city: string;
  state: string;
  zip: string;
}

export interface PharmacyDtoExclusiveBrand {
  name: string;
  href: string;
  set_id: string;
}
