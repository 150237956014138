import {ReactHTML} from 'react';
import {NEXT_PUBLIC_IMGS_URL} from '@/constants/global';

export const alphabet = [...'abcdefghijklmnopqrstuvwxyz'];

export function isVowel(s: string) {
  return /^[aeiou]$/i.test(s.toLocaleLowerCase());
}

export const getArticle = (message: string) => {
  const firstLetter = message.charAt(0);
  if (isVowel(firstLetter)) {
    return 'an';
  }
  return 'a';
};

export const joinWords = (words: string[], lastConnector = 'or') => {
  if (words?.length <= 1) return words[0] || '';
  const initialWords = words.slice(0, words?.length - 1);
  const lastWord = words[words?.length - 1];
  return `${initialWords.join(', ')} ${lastConnector} ${lastWord}`;
};

export function capitalizeFirstLetter(word: String) {
  if (!word) return '';
  return word.charAt(0).toUpperCase() + word.slice(1);
}

export function capitalize(word: String) {
  if (!word) return '';
  return capitalizeFirstLetter(word.toLocaleLowerCase());
}

export function splitAndTrim(word: string) {
  return word.split(',').map((w) => w.trim());
}

export function getFirstSentence(str: string) {
  const regex = /.*?(\.)(?=\s[A-Z])/;
  return str.match(regex)?.[0] || str;
}

interface IStrVariables {
  [name: string]: string;
}

export function replaceVariablesInStr(str: string, variables: IStrVariables) {
  if (!str) return str;
  let newStr = str;
  const keys = Object.keys(variables);
  keys.forEach((varName) => {
    const reg = new RegExp(`{${varName}}`, 'g');
    newStr = newStr.replace(reg, variables[varName]);
  });
  return newStr;
}

export const removeAllHTMLElementInStr = (
  str: string,
  htmlEl: keyof ReactHTML,
) => {
  const strToBeReplaced = `<${htmlEl}>(.*?)</${htmlEl}>`;
  const regex = new RegExp(strToBeReplaced, 'g');
  return str.replaceAll(regex, '');
};

export function removeTableWidthAttribute(str: string): string {
  return str.replace(/<table[^>]*\bwidth="\d+%?"[^>]*>/g, function (match) {
    return match.replace(/\bwidth="\d+%?"\s*/g, '');
  });
}

export const replaceAllHTMLElementInStr = (
  str: string,
  oldTag: keyof ReactHTML,
  newTag: keyof ReactHTML,
) => {
  const openTagOld = `<${oldTag}>`;
  const closeTagOld = `</${oldTag}>`;
  const openTagNew = `<${newTag}>`;
  const closeTagNew = `</${newTag}>`;
  return str
    .replaceAll(openTagOld, openTagNew)
    .replaceAll(closeTagOld, closeTagNew);
};

export const removeAnchors = (str: string) =>
  str
    .replaceAll(/(\(<a[^>]*>)(.*?)(<\/a>\))/g, '') // remove anchors links in parentheses, e.g. (5.1)
    .replaceAll(/(<a[^>]*>)(.*?)(<\/a>)/g, ' $2'); // replace anchors links, e.g. <a href="#id">Table 1</a> => Table 1

export const removeHeaderNumbers = (str: string) =>
  str.replaceAll(/<h2[^>]*>(<span [^>]*><\/span>)*\d+\.\d+/g, '<h2>'); // remove header numbers, e.g. <h2 style="border-left:1px solid;"><span class="XmChange"></span>2.1 Testing and Procedures Prior to Treatment Initiation</h2> => <h2>2.1 Testing and Procedures Prior to Treatment Initiation</h2>

export const removeHeaderNumbersAndAnchors = (str: string) =>
  removeAnchors(removeHeaderNumbers(str));

export function camelToUnderscore(key: string) {
  return key.replace(/([A-Z])/g, '_$1').toLowerCase();
}
// big animal ->  big-animal
export const toKebabCase = (str = '') => {
  const strArr = str.trim().split(' ');
  const snakeArr = strArr.reduce(
    (acc: string[], val: string) => acc.concat(val.toLowerCase()),
    [],
  );
  return snakeArr.join('-');
};

export const toSnakeCase = (str = '') => {
  const strArr = str.trim().split(' ');
  const snakeArr = strArr.reduce(
    (acc: string[], val: string) => acc.concat(val.toLowerCase()),
    [],
  );
  return snakeArr.join('_');
};

// big-animal =>  Big Animal
// big--animal =>  Big  Animal
export const kebabToTitleCase = (input: string) =>
  input.replace(/-/g, ' ').replace(/\b\w/g, (c) => c.toUpperCase());
// Big_Animal => Big Anmial
export const snakeToTitleCase = (kebabCase: string) =>
  kebabCase.replace(/^_*(.)|_+(.)/g, (s, c, d) =>
    c ? c.toUpperCase() : ' ' + d.toUpperCase(),
  );

export const updateImgSrcLink = (str: string, setId: string) =>
  str.replace(
    /<img([^>]*)src="([^/]*)"\/>/gi, //remove relative path from img src e.g. <img src="/imgPath.jpg" />
    `<img $1 src="${NEXT_PUBLIC_IMGS_URL}${setId}/$2" loading="lazy"/>`, // replace src path with newPath <img src="{customPath}/{setId}/imgPath.jpg"
  );

export const formatDosageHTML = (str: string) => {
  let formattedStr = removeAllHTMLElementInStr(str, 'dt');
  const tagsToReplace = [
    {old: 'dl', new: 'ul'},
    {old: 'dd', new: 'li'},
    {old: 'li', new: 'label'},
  ];

  tagsToReplace.forEach((tag) => {
    formattedStr = replaceAllHTMLElementInStr(
      formattedStr,
      tag.old as keyof ReactHTML,
      tag.new as keyof ReactHTML,
    );
  });

  return formattedStr
    .replaceAll('</label>', '</label></br>')
    .replaceAll('<label>', '<label>* ')
    .replaceAll('<ul', '</br><label')
    .replaceAll('</ul>', '</label>')
    .replace('</br>', '')
    .replace(/\s{9}/g, ''); // Replace 9 whitespaces
};

export const splitEmail = (email: string) => {
  const [name, domain] = email.split('@');
  return {name, domain};
};

export const compareAlphabetically = (a: string, b: string) =>
  a.toLowerCase() > b.toLowerCase();

export const sortedAlpha = (a: string, b: string): number => {
  const numA = extractNumber(a);
  const numB = extractNumber(b);
  return numA !== numB ? numA - numB : a.localeCompare(b);
};

export function formatUSD(value: number): string {
  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  }).format(value);
}

export function extractNumber(sentence: string) {
  return parseFloat((sentence.match(/\d+(\.\d+)?/) ?? [])[0] || '');
}
